import React from 'react';
import store from "../store";
import ProfileThemes from "./ProfileThemes";
import ProfileSettings from "./ProfileSettings";
import copy from "copy-to-clipboard";

export default class ProfileSettingsBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showSettings: false,
      showProfileUrl: false,
      showThemes: false,
      isCopied: false,
      styles: store.getState().styles
    };

    store.subscribe(() =>
      this.setState({ styles: store.getState().styles })
    )
  }

  underLineTabs(tab) {
    const { showSettings, showProfileUrl, showThemes } = this.state
    const underline = { borderBottom: `2px solid black` }

    if (tab == "settings" && showSettings) {
      return underline
    }

    if (tab == "profileUrl" && showProfileUrl) {
      return underline
    }

    if (tab == "themes" && showThemes) {
      return underline
    }
  }

  showProfileUrl = () => {
    const { showProfileUrl } = this.state

    this.setState({ showProfileUrl: !showProfileUrl, showSettings: false, showThemes: false })
  }

  showThemes = () => {
    const { showThemes } = this.state

    this.setState({ showThemes: !showThemes, showSettings: false, showProfileUrl: false })
  }

  showSettings = () => {
    const { showSettings } = this.state

    this.setState({ showSettings: !showSettings, showProfileUrl: false, showThemes: false })
  }

  copyProfileUrl() {
    const { user } = this.props;

    copy(`https://shopmyfeed.io/${user.slug}`)

    this.setState({ isCopied: true })
  }

  render() {
    const { styles, showSettings, showProfileUrl, isCopied, showThemes } = this.state;
    const { authToken, user } = this.props;

    return (
      <div className="bg-grey-xxlight">
        {
          authToken != undefined &&
          <div className="bg-white border-grey-light" style={{ borderLeft: "1px solid", borderRight: "1px solid", borderBottom: "1px solid" }}>
            <div className="container-no-padding max-w800px flex font-small text-black">
              <div className="p2" onClick={() => this.showProfileUrl()} style={this.underLineTabs("profileUrl")}>
                <p className="mb0 uppercase">Profile Url</p>
              </div>
              <div className="p2" onClick={() => this.showThemes()} style={this.underLineTabs("themes")}>
                <p className="mb0 uppercase">Themes</p>
              </div>
              <div className="p2" onClick={() => this.showSettings()} style={this.underLineTabs("settings")}>
                <p className="mb0 uppercase">Customize</p>
              </div>
            </div>
          </div>
        }

        {
          showProfileUrl &&
          <div className="container-no-padding max-w800px">
            <div className="flex items-center p2" onClick={() => this.copyProfileUrl()}>
              <span className="bg-white p1 px3 rounded" style={{ borderRadius: "50px" }}> {`https://shopmyfeed.io/${user.slug}`} </span>
              {!isCopied && <i className="far fa-lg fa-copy pl2" />}
              {isCopied && <div className="ml1 bold">Copied!</div>}
            </div>
          </div>
        }

        {
          showThemes &&
          <ProfileThemes
            user={user}
            authToken={authToken}
            updateReduxState={this.updateReduxState}
            close={this.showThemes}
          />
        }

        {showSettings &&
          <ProfileSettings
            user={user}
            styles={styles}
            authToken={authToken}
            updateReduxState={this.updateReduxState}
            close={this.showSettings}
          />
        }
      </div>
    )
  }
}