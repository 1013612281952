import React, { Component } from "react";
import ColorPicker from "../shared/ColorPicker";
import ProfileSetting from "../services/ProfileSetting";
import { ClipLoader } from "react-spinners";
import store from "../store";

export default class ProfileColours extends Component {
  constructor(props) {
    super(props);

    const styles = store.getState().styles

    this.state = {
      bg_colour: styles.bg_colour,
      social_colour: styles.social_colour,
      bg_gradient_colour_1: styles.bg_gradient.colour_1,
      bg_gradient_colour_2: styles.bg_gradient.colour_2,
      button_bg_colour: styles.button_bg_colour,
      button_text_colour: styles.button_text_colour,
      header_text_colour: styles.header_text_colour,
      links_text_colour: styles.links_text_colour,
      background_url: styles.background_url,
      isOpen: false,
      colorPickerTarget: "",
      hasSaved: false,
      loading: false
    };

    store.subscribe(() =>
      this.setState({styles: store.getState().styles })
    )

    this.profileSettingService = new ProfileSetting();
  }

  handeBgColor = async (target, val) => {
    const { updateReduxState } = this.props;
    const { bg_gradient_colour_1, bg_gradient_colour_2 } = this.state;

    if (target == "bg_gradient_colour_1") {
      updateReduxState("bg_gradient", { colour_1: val, colour_2: bg_gradient_colour_2 })
      updateReduxState("bg_colour", "")
      updateReduxState("background_url", "")
      this.setState({ bg_gradient_colour_1: val, bg_colour: "", background_url: "" });
    } else if (target == "bg_gradient_colour_2") {
      updateReduxState("bg_gradient", { colour_1: bg_gradient_colour_1, colour_2: val })
      updateReduxState("bg_colour", "")
      updateReduxState("background_url", "")
      this.setState({ bg_gradient_colour_2: val, bg_colour: "", background_url: "" });
    } else if (target == "bg_colour") {
      updateReduxState("bg_gradient", { colour_1: "", colour_2: "" })
      updateReduxState("bg_colour", val)
      updateReduxState("background_url", "")
      this.setState({ bg_gradient_colour_1: "", bg_gradient_colour_2: "", bg_colour: val, background_url: "" });
    } else {
      updateReduxState(target, val)
      
      const key = target;
      const obj = {};
      obj[key] = val;
      this.setState(obj);
    }
  };

  closeModal = () => {
    this.setState({ isOpen: false });
  };

  setColor(color) {
    this.setState({
      isOpen: true,
      colorPickerTarget: color,
    });
  }

  async updateProfileSetting() {
    const { styles, bg_colour, bg_gradient_colour_1, bg_gradient_colour_2, header_text_colour, links_text_colour, social_colour, background_url } = this.state;
    const { authToken } = this.props;
    const params = {
      bg_colour: bg_colour,
      bg_gradient: { colour_1: bg_gradient_colour_1, colour_2: bg_gradient_colour_2 },
      header_text_colour: header_text_colour,
      links_text_colour: links_text_colour,
      social_colour: social_colour,
      uuid: styles.uuid,
    };

    
    if(background_url == "") {
      params["background_image"] = null
    }

    this.setState({loading: true})
    await this.profileSettingService.update(authToken, { profile_setting: params, uuid: styles.uuid})
    this.setState({hasSaved: true, loading: false})

    setTimeout(() => {
      this.setState({hasSaved: false})
    }, 2000);
  }

  render() {
    const {
      bg_colour,
      bg_gradient_colour_1,
      bg_gradient_colour_2,
      header_text_colour,
      links_text_colour,
      social_colour,
      isOpen,
      colorPickerTarget,
      hasSaved,
      loading
    } = this.state;

    return (
      <div className="container-no-padding max-w800px bg-white rounded p3 font-small rounded">
        {
          hasSaved && <div className="bg-green p2 font-small rounded mb2 text-white">
            Saves successfully
          </div>
        
        }
        <ColorPicker
          isOpen={isOpen}
          handleChange={this.handeBgColor}
          close={this.closeModal}
          target={colorPickerTarget}
        />

        <label className="mb0">Background Color</label>
        <div className="flex">
          <input
            type="text"
            name="bg_colour"
            style={{ width: "100%" }}
            value={bg_colour}
            onChange={(event) => this.handeBgColor("bg_colour", event.target.value)}
          />
          <div
            className="rounded ml1 border-full border-grey-dark"
            onClick={() => this.setColor("bg_colour")}
            style={{
              backgroundColor: bg_colour,
              width: "45px",
              height: "45px",
            }}
          ></div>
        </div>

        <label className="mb0">Background Gradient</label>
        <div className="flex">
          <input
            type="text"
            name="bg_gradient_colour_1"
            style={{ width: "100%" }}
            value={bg_gradient_colour_1}
            onChange={(event) => this.handeBgColor("bg_gradient_colour_1", event.target.value)}
          />
          <div
            className="rounded mx1 border-full border-grey-dark"
            onClick={() => this.setColor("bg_gradient_colour_1")}
            style={{
              backgroundColor: bg_gradient_colour_1,
              width: "90px",
              height: "45px",
            }}
          ></div>
          <input
            type="text"
            name="bg_gradient_colour_2"
            style={{ width: "100%" }}
            value={bg_gradient_colour_2}
            onChange={(event) => this.handeBgColor("bg_gradient_colour_2", event.target.value)}
          />
          <div
            className="rounded ml1 border-full border-grey-dark"
            onClick={() => this.setColor("bg_gradient_colour_2")}
            style={{
              backgroundColor: bg_gradient_colour_2,
              width: "90px",
              height: "45px",
            }}
          ></div>
        </div>

        <label className="mb0">Username Font Colour</label>
        <div className="flex">
          <input
            type="text"
            name="header_text_colour"
            style={{ width: "100%" }}
            value={header_text_colour}
            onClick={() => this.setColor("header_text_colour")}
          />
          <div
            className="rounded ml1 border-full border-grey-dark"
            onClick={() => this.setColor("header_text_colour")}
            style={{
              backgroundColor: header_text_colour,
              width: "45px",
              height: "45px",
            }}
          ></div>
        </div>

        <label className="mb0">Navigation Links Colour</label>
        <div className="flex">
          <input
            type="text"
            name="links_text_colour"
            style={{ width: "100%" }}
            value={links_text_colour}
            onClick={() => this.setColor("links_text_colour")}
          />
          <div
            className="rounded ml1 border-full border-grey-dark"
            onClick={() => this.setColor("links_text_colour")}
            style={{
              backgroundColor: links_text_colour,
              width: "45px",
              height: "45px",
            }}
          ></div>
        </div>

        <label className="mb0">Social Links Colour</label>
        <div className="flex">
          <input
            type="text"
            name="social_colour"
            style={{ width: "100%" }}
            value={social_colour}
            onChange={(event) => this.handeBgColor("social_colour", event.target.value)}
          />
          <div
            className="rounded ml1 border-full border-grey-dark"
            onClick={() => this.setColor("social_colour")}
            style={{
              backgroundColor: social_colour,
              width: "45px",
              height: "45px",
            }}
          ></div>
        </div>

        <button
          className="button button-blue bold w100"
          onClick={() => this.updateProfileSetting()}
        >
          Save
        </button>

        {
          loading && <div className="flex justify-center">
            <div className="sweet-loading">
              <ClipLoader color={"#000000"} loading={true} />
            </div>
          </div>
        }
      </div>
    );
  }
}
