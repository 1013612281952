import React from 'react';
import store from "../store";
import Medias from "../medias/Medias";
import ProfileHeader from "./ProfileHeader";
import UserProductsWrapper from "../products/UserProductsWrapper";
import LinkList from "../links/LinkList";

export default class ProfileWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      styles: this.props.styles
    };

    store.subscribe(() =>
      this.setState({ styles: store.getState().styles })
    )

    this.setRedux()
  }

  setRedux() {
    const { styles } = this.props;

    store.dispatch({ type: 'counter/incremented', payload: styles })
  }

  bgStyles() {
    const { styles } = this.state;

    if (styles.bg_colour != "") {
      return { backgroundColor: styles.bg_colour }
    } else if (styles.background_url) {
      return { background: `url(${styles.background_url}) center center / cover no-repeat fixed`, backgroundSize: "cover" }
    } else {
      const colour_1 = styles.bg_gradient.colour_1 == "" || styles.bg_gradient.colour_1 == null ? "white" : styles.bg_gradient.colour_1
      const colour_2 = styles.bg_gradient.colour_2 == "" || styles.bg_gradient.colour_2 == null ? "white" : styles.bg_gradient.colour_2
      return { backgroundImage: `linear-gradient(${colour_1}, ${colour_2})` }
    }
  }

  showLinkList() {
    const { user } = this.props;
    return window.location.pathname == `/${user.slug}` || window.location.pathname == "/"
  }

  render() {
    const { authToken, user, instagram_login_url, isProfileUser } = this.props;

    return (
      <div 
        className="min-h100vh"
        style={this.bgStyles()}
      >
        <ProfileHeader 
          authToken={authToken}
          user={user}
        />

        {
          this.showLinkList() &&
          <LinkList 
            authToken={authToken}
            user={user} 
          />
        }

        {
          window.location.pathname == `/${user.slug}/images` && 
          <Medias
            instagram_login_url={instagram_login_url}
            user={user} 
            user_media_uuid={user.uuid}
            authToken={authToken}
            isProfileUser={isProfileUser}
          />
        }

        {
          window.location.pathname == `/${user.slug}/products` && 
          <UserProductsWrapper
            profileUser={user} 
            authToken={authToken}
            canDelete={isProfileUser}
          />
        }
      </div>
    )
  }
}