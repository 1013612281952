export default class InstagramPostProductsService {
  show(instagram_short_code_or_id) {
    return fetch(
      `/api/v1/instagram_post_products/${instagram_short_code_or_id}/`,
      {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
        }),
      }
    ).then((res) => {
      return res.json();
    });
  }

  create(params, authToken) {
    return fetch("/api/v1/instagram_post_products", {
      method: "POST",
      body: JSON.stringify(params),
      headers: new Headers({
        "Content-Type": "application/json",
        "X-AUTH-TOKEN": authToken,
      }),
    }).then((res) => {
      return res.json();
    });
  }

  destroy(instagram_post_product_uuid, authToken) {
    return fetch(
      `/api/v1/instagram_post_products/${instagram_post_product_uuid}`,
      {
        method: "DELETE",
        headers: new Headers({
          "Content-Type": "application/json",
          "X-AUTH-TOKEN": authToken,
        }),
      }
    ).then((res) => {
      return res.json();
    });
  }
}
