import React from 'react';
import { ClipLoader } from "react-spinners";
import store from "../store";
var mobile = require("is-mobile");

export default class ProfileThemes extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      prototypes: []
    }

    store.subscribe(() =>{
      this.setState({profileSettingPrototypes: store.getState().profileSettingPrototypes })
    })

    if(store.getState().profileSettingPrototypes.length == 0) {
      this.fetchProfileSettingPrototypes()
    }
  }

  componentWillMount() {
    const prototypes = store.getState().profileSettingPrototypes

    if(prototypes.length > 0) {
      this.setState({ loading: false, prototypes: store.getState().profileSettingPrototypes })
    }
  }

  async fetchProfileSettingPrototypes() {
    const { authToken } = this.props;
    this.setState({loading: true})

    const res = await fetch('/api/v1/profile_setting_prototypes', {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        "X-AUTH-TOKEN": authToken,
      }),
    }).then((res) => {
      return res.json();
    });

    store.dispatch({ type: 'profileSettingPrototypes', payload: res })
    this.setState({ loading: false, prototypes: res })
  }

  async setProfile(prototype) {
    const { authToken, close } = this.props;

    this.setState({ loading: true })

    const res = await fetch(`/api/v1/profile_settings_copy/${prototype.uuid}`, {
      method: 'PATCH',
      headers: new Headers({
        'Content-Type': 'application/json',
        'X-AUTH-TOKEN': authToken,
      })
    }).then(res => {
      return res.json();
    })

    store.dispatch({ type: 'counter/incremented', payload: res })
    this.setState({ loading: false })
    close()
  }


  render() {
    const { loading, prototypes } = this.state;
    const isMobile = mobile()
    const cols = isMobile ? [[], []] : [[], [], []];
    const indexCount = isMobile ? 2 : 3;
    const cssColumns = isMobile ? "c1in2" : "c1in3";
    prototypes.map((media, index) => {
      cols[index % indexCount].push(media);
    });

    return (
      <div className="bg-grey-xxlight">
        <div className="container-no-padding p1 max-w800px">
          {
            !loading && <div className="flex justify-between cell-list mt1">
              {cols.map((col, index) => (
                <div className={`${cssColumns}`} key={index}>
                  {col.map((prototype) => (
                    <div className="p1">
                      <div 
                        className="flex flex-column relative w100 border-full border-grey-light rounded" 
                        style={{
                          flexDirection: "column"
                        }}
                      >
                        <img 
                          className="rounded pointer" 
                          style={{width: "100%", height: "auto"}}
                          src={`${prototype.display_image_url}`} onClick={() => this.setProfile(prototype)}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          }

          { loading && (
            <div className="flex justify-center py6">
              <div className="bg-white p1" style={{ borderRadius: "40px", display: "inherit" }}>
                <ClipLoader color={"black"} loading={loading}  />
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}