import React from "react";
import { PropTypes } from "prop-types";
import ProductService from "../services/ProductService";
import MonetizeUrlService from "../services/MonetizeUrlService";
import { get } from "lodash";
import { ClipLoader } from "react-spinners";

export default class CreateForm extends React.Component {
  static propTypes = {
    authToken: PropTypes.string,
    userUuid: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      file: null,
      errors: null,
      productUuid: get(props, "product.uuid"),
      file: get(props, "product.image_url"),
      title: get(props, "product.title"),
      price: this.getPrice(get(props, "product.price")),
      redirect_url: get(props, "product.redirect_url"),
      promo_code: get(props, "product.promo_code"),
      monetizedLink: null,
      loading: false,
      canMonetizeLink: false,
      rates: null,
      loadingLink: false,
      canSearch: false,
    };

    this.productService = new ProductService();
    this.MonetizeUrlService = new MonetizeUrlService();
  }

  getPrice(price) {
    if (price != undefined) {
      return price.replace(/[^0-9.]/g, "");
    }
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  };

  handleUrlChange = async (event) => {
    this.setState({
      redirect_url: event.target.value,
      canSearch: true,
      canMonetizeLink: false,
      monetizedLink: null,
    });
  };

  findMonetizeLink = async (event) => {
    const { authToken } = this.props;
    const { redirect_url } = this.state;

    this.setState({
      loadingLink: true,
      canSearch: false,
    });

    const res = await this.MonetizeUrlService.index(redirect_url, authToken);

    if (res.url) {
      this.setState({
        loadingLink: false,
        monetizedLink: res.url,
        canMonetizeLink: res.approved,
        rate: res.rate,
        cpa: res.cost_per_action,
        cpc: res.cost_per_click,
      });
    }
  };

  useLink = () => {
    const { monetizedLink } = this.state;
    this.setState({
      canMonetizeLink: false,
      monetizedLink: null,
      redirect_url: monetizedLink,
    });
  };

  handleUpload = async (event) => {
    const imageUrl = URL.createObjectURL(event.target.files[0]);
    const base64Image = await this.convertToBase64(event.target.files[0]);

    this.setState({
      base_64_image: base64Image,
      file: imageUrl,
    });
  };

  async convertToBase64(file) {
    return new Promise(resolve => {
      let baseURL = "";
      let reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  }

  createProduct = async () => {
    const {
      base_64_image,
      title,
      price,
      redirect_url,
      promo_code,
    } = this.state;

    const { authToken, userUuid } = this.props;

    const params = {
      product: {
        base_64_image: base_64_image,
        title: title,
        price: price,
        redirect_url: redirect_url,
        promo_code: promo_code,
      },
    };

    this.setState({ loading: true });

    const response = await this.productService.create(
      authToken,
      userUuid,
      params
    );

    if (response.error) {
      const errors = response.error;
      this.setState({ errors, loading: false });
    } else {
      window.location = `/${response.user.slug}/products`;
    }
  };

  updateProduct = async () => {
    const {
      base_64_image,
      title,
      price,
      redirect_url,
      promo_code,
      productUuid,
    } = this.state;

    const { authToken, userUuid } = this.props;

    const params = {
      product: {
        title: title,
        price: price,
        redirect_url: redirect_url,
        promo_code: promo_code,
      },
    };

    
    if (base_64_image != undefined) {
      params["product"]["base_64_image"] = `${base_64_image}`;
    }

    this.setState({ loading: true });

    const response = await this.productService.update(
      authToken,
      userUuid,
      productUuid,
      params
    );

    if (response.error) {
      const errors = response.error;
      this.setState({ errors, loading: false });
    } else {
      window.location = `/${response.user.slug}/products`;
    }
  };

  render() {
    const {
      errors,
      title,
      price,
      redirect_url,
      promo_code,
      productUuid,
      monetizedLink,
      loading,
      canMonetizeLink,
      rate,
      cpc,
      cpa,
      loadingLink,
      canSearch,
    } = this.state;

    return (
      <div>
        <label>
          <label>
            <p className="mb0 extra-bold">URL</p>
            <input
              type="text"
              name="redirect_url"
              style={{ width: "100%" }}
              value={redirect_url}
              onChange={this.handleUrlChange}
            />
            { canSearch && (
              <div className="bg-blue-xxlight p3 rounded mb2">
                <div className="flex flex-column center-align">
                  <button
                    className="button button-blue w100 mt1"
                    onClick={this.findMonetizeLink}
                  >
                    Convert to monetize link
                  </button>
                </div>
              </div>
            )}
            {loadingLink && (
              <div className="bg-blue-xxlight p3 rounded mb2">
                <div className="flex flex-column center-align">
                  Creating Monetized Link
                  <div className="center-align pt1">
                    <ClipLoader color={"#000000"} loading={true} />
                  </div>
                </div>
              </div>
            )}
            {monetizedLink != null && canMonetizeLink && (
              <div className="bg-blue-xxlight p3 rounded mb2">
                <p className="mb1 bold">Yay! This product can be monetized.</p>
                <div className="flex mb1">
                  <div className="flex">
                    You will earn money:
                    {cpa && (
                      <div className="ml2 text-green bold underline-text-green">
                        {rate} Per sale
                      </div>
                    )}
                    {cpc && (
                      <div className="ml2 text-green bold underline-text-green">
                        Per Click
                      </div>
                    )}
                  </div>
                </div>
                <p className="mb2 text-blue truncate">{monetizedLink}</p>
                <button
                  className="button button-blue button-small w100"
                  onClick={this.useLink}
                >
                  Use monetized link
                </button>
                <p className="mt2">Click on Commission Report in navigation to view your monthly payouts.</p>
              </div>
            )}
            {monetizedLink != null && !canMonetizeLink && (
              <div className="bg-brand-tan p3 rounded mb2">
                <p className="mb1 bold">Link can not be monetized</p>
                <p className="mb1">
                  The brand is not part of our netowrk. You can still create a
                  product using your link.
                </p>
              </div>
            )}
          </label>
          <p className="mb0 extra-bold">Upload image</p>
          <div>
            <input
              className="h100px w100 bg-blue-xxlight rounded p2 mb1 border-full border-blue-light"
              name="file"
              type="file"
              onChange={(e) => this.handleUpload(e)}
            />
          </div>
          <img
            className="flex justify-center max-w150px max-h100px pt1"
            src={this.state.file}
          />
        </label>
        <label>
          <p className="mb0 mt2 extra-bold">Title</p>
          <input
            type="text"
            name="title"
            style={{ width: "100%" }}
            value={title}
            onChange={this.handleInputChange}
          />
        </label>
        <label>
          <p className="mb0 extra-bold">Price</p>
          <input
            type="number"
            name="price"
            placeholder="19.95 - Not required"
            style={{ width: "100%" }}
            value={price}
            onChange={this.handleInputChange}
          />
        </label>
        <label>
          <p className="mb0 extra-bold">Promo code</p>
          <h6 className="mb1 italic bg-brand-tan p1 rounded">Promocode will be copied to clipboard when someone clicks on your product.</h6>
          <input
            type="text"
            name="promo_code"
            placeholder="Not Required"
            style={{ width: "100%" }}
            value={promo_code}
            onChange={this.handleInputChange}
          />
        </label>

        {errors != null && (
            <div className="px1 border text-red">{errors}</div>
        )}

        <button
          className="button button-blue button-large w100"
          onClick={
            productUuid != undefined ? this.updateProduct : this.createProduct
          }
        >
          {loading && <ClipLoader color={"#ffffff"} loading={true} />}
          {!loading && <div className="bold">Save</div>}
        </button>
      </div>
    );
  }
}
