export default class ProfileSettingService {
  update(authToken, params) {
    return fetch(`/api/v1/profile_settings/${params.uuid}`, {
      method: "PATCH",
      body: JSON.stringify(params),
      headers: new Headers({
        "Content-Type": "application/json",
        "X-AUTH-TOKEN": authToken,
      }),
    }).then((res) => {
      return res.json();
    });
  }
}
