export default class LinksService {
  index(userUuid) {
    return fetch(`/api/v1/links?user_uuid=${userUuid}`, {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json"
      }),
    }).then((res) => {
      return res.json();
    });
  }

  create(params, authToken) {
    return fetch(`/api/v1/links`, {
      method: "POST",
      body: JSON.stringify(params),
      headers: new Headers({
        "Content-Type": "application/json",
        "X-AUTH-TOKEN": authToken,
      }),
    }).then((res) => {
      return res.json();
    });
  }

  update(params, authToken) {
    return fetch(`/api/v1/links/${params.link.uuid}`, {
      method: "PATCH",
      body: JSON.stringify(params),
      headers: new Headers({
        "Content-Type": "application/json",
        "X-AUTH-TOKEN": authToken,
      }),
    }).then((res) => {
      return res.json();
    });
  }

  destroy(uuid, authToken) {
    return fetch(`/api/v1/links/${uuid}`, {
      method: "DELETE",
      headers: new Headers({
        "Content-Type": "application/json",
        "X-AUTH-TOKEN": authToken,
      }),
    }).then((res) => {
      return res.json();
    });
  }
}