import React, { Component } from "react";
import { PropTypes } from "prop-types";
import ImageOfInterestService from "../services/ImageOfInterestService";
import FetchInstagramPost from "../services/FetchInstagramPost";
import Loader from "../shared/Loader";
import ImageDashboardUploader from "./ImageDashboardUploader";

export default class InstagramPostUploader extends Component {
  static propTypes = {
    auth_token: PropTypes.string
  };

  constructor(props) {
    super(props);

    this.state = {
      instagramPostUrl: "",
      isLoading: false
    };

    this.createImageOfInterest = new ImageOfInterestService();
    this.fetchInstagramPost = new FetchInstagramPost();
  }

  handleInputChange = async () => {
    this.setState({
      instagramPostUrl: this.refs.inputValue.value,
      isLoading: true
    });

    const { auth_token } = this.props;
    const params = {
      instagram_post_url: this.refs.inputValue.value
    };

    const response = await this.fetchInstagramPost.index(params, auth_token);
    this.instagramPostDetails(response);
  };

  async instagramPostDetails(response) {
    const { auth_token } = this.props;
    const params = {
      image_of_interest: {
        image: response.image_url
      }
    };

    const imageOfInterest = await this.createImageOfInterest.create(
      params,
      auth_token
    );

    this.redirectToImageOfInterest(imageOfInterest);
  }

  redirectToImageOfInterest = imageOfInterest => {
    window.location = `/similar_products?uuid=${imageOfInterest.uuid}`;
  };

  render() {
    const { instagramPostUrl, isLoading } = this.state;

    if (isLoading) {
      return (
        <div className="pt4">
          <Loader />
        </div>
      );
    } else {
      return (
        <div className="px1 flex justify-end relative">
          <input
            className="center-align w100 text-black"
            placeholder="Paste Instagram Post URL"
            id="instagram_post"
            type="text"
            ref="inputValue"
            value={instagramPostUrl}
            onChange={this.handleInputChange}
            style={{
              backgroundColor: "white",
              color: "black",
              padding: "2rem",
              border: "none",
              borderRadius: "30px"
            }}
          />
          <div className="absolute" style={{ top: "-7px" }}>
            <ImageDashboardUploader auth_token={this.props.auth_token} />
          </div>
        </div>
      );
    }
  }
}
