import WebpackerReact from 'webpacker-react'

import ImageChanger from '../components/images/ImageChanger';

import ProductCreateForm from '../components/products/CreateForm'
import Analytics from '../components/products/Analytics'
import Navbar from '../components/products/Navbar'
import UserProducts from '../components/products/UserProducts'
import UserProductsWrapper from '../components/products/UserProductsWrapper'

import UserIncomeReport from '../components/users/UserIncomeReport';
import ProfileSettings from '../components/users/ProfileSettings';
import ProfileHeader from '../components/users/ProfileHeader';
import ProfileSettingsBar from '../components/users/ProfileSettingsBar';
import ProfileWrapper from '../components/users/ProfileWrapper';

import ProductList from '../components/list/ProductList';
import UserList from '../components/list/UserList';

import Medias from '../components/medias/Medias';
import Media from '../components/medias/Media';
import ShopMedia from '../components/medias/ShopMedia';

import FlashMessage from '../components/FlashMessage';
import Search from '../components/search/Search';
import LinkList from '../components/links/LinkList';
import ClickAnalytics from '../components/analytics/ClickAnalytics';
import SetProfileSettingsButton from '../components/profile_settings/SetProfileSettingsButton';
import InstagramPostUploader from '../components/image_dashboard/InstagramPostUploader';
import RoiSelect from '../components/image_of_interest/RoiSelect';

window.flash = function(msg, type) {
  document.querySelector("#flash-messages").innerHTML = `<div class="alert ${type}">${msg}</div>`;
  setTimeout(function(){
    document.querySelector("#flash-messages .alert").className = 'alert ${type} hide';
  }, 5000);
};

require('whatwg-fetch');

WebpackerReact.setup({
  ImageChanger,
  Analytics,
  Navbar,
  UserProducts,
  UserProductsWrapper,
  ProductCreateForm,
  UserIncomeReport,
  ProfileSettings,
  ProfileHeader,
  ProfileSettingsBar,
  ProfileWrapper,
  ProductList,
  UserList,
  Medias,
  Media,
  ShopMedia,
  FlashMessage,
  Search,
  LinkList,
  ClickAnalytics,
  SetProfileSettingsButton,
  InstagramPostUploader,
  RoiSelect
});