import React, { Component } from "react";
import ProfileSetting from "../services/ProfileSetting";
import { ClipLoader } from "react-spinners";
import store from "../store";

export default class ProfileSocials extends Component {
  constructor(props) {
    super(props);

    const styles = store.getState().styles

    this.state = {
      instagram_url: styles.instagram_url,
      linkedin_url: styles.linkedin_url,
      tiktok_url: styles.tiktok_url,
      youtube_url: styles.youtube_url,
      twitter_url: styles.twitter_url,
      twitch_url: styles.twitch_url,
      facebook_url: styles.facebook_url,
      loading: false,
    };

    this.profileSettingService = new ProfileSetting();
  }

  handleChange(key, value) {
    this.setState({[key]: value})
  }

  async updateProfileSetting() {
    const { instagram_url, linkedin_url, tiktok_url, youtube_url, 
            twitter_url, twitch_url, facebook_url  } = this.state;
    const { authToken } = this.props
    const styles = store.getState().styles


    const params = {
      uuid: styles.uuid,
      profile_setting: {
        instagram_url: instagram_url,
        linkedin_url: linkedin_url,
        tiktok_url: tiktok_url,
        youtube_url: youtube_url,
        twitter_url: twitter_url,
        twitch_url: twitch_url, 
        facebook_url: facebook_url
      }
    }

    this.setState({loading: true})
    await this.profileSettingService.update(authToken, params);
    location.reload()
  }

  render() {
    const { instagram_url, facebook_url, linkedin_url, tiktok_url, youtube_url, twitter_url, twitch_url, loading } = this.state;

    return (
      <div className="container-no-padding max-w800px bg-white rounded p3 font-small rounded">
        <p className="mb2">Did you know? Social icons can be displayed under your links.</p>
        <label className="mb0">Instagram</label>
        <input
          type="text"
          name="instagram_url"
          style={{ width: "100%" }}
          value={instagram_url}
          onChange={(event) => this.handleChange("instagram_url", event.target.value)}
        />

        <label className="mb0">Facebook</label>
        <input
          type="text"
          name="facebook_url"
          style={{ width: "100%" }}
          value={facebook_url}
          onChange={(event) => this.handleChange("facebook_url", event.target.value)}
        />

        <label className="mb0">Youtube</label>
        <input
          type="text"
          name="youtube_url"
          style={{ width: "100%" }}
          value={youtube_url}
          onChange={(event) => this.handleChange("youtube_url", event.target.value)}
        />

        <label className="mb0">Twitter</label>
        <input
          type="text"
          name="twitter_url"
          style={{ width: "100%" }}
          value={twitter_url}
          onChange={(event) => this.handleChange("twitter_url", event.target.value)}
        />

        <label className="mb0">Twitch</label>
        <input
          type="text"
          name="twitch_url"
          style={{ width: "100%" }}
          value={twitch_url}
          onChange={(event) => this.handleChange("twitch_url", event.target.value)}
        />

        <label className="mb0">LinkedIn</label>
        <input
          type="text"
          name="linkedin_url"
          style={{ width: "100%" }}
          value={linkedin_url}
          onChange={(event) => this.handleChange("linkedin_url", event.target.value)}
        />

        <label className="mb0">Tiktok</label>
        <input
          type="text"
          name="tiktok_url"
          style={{ width: "100%" }}
          value={tiktok_url}
          onChange={(event) => this.handleChange("tiktok_url", event.target.value)}
        />

        <button
          className="button button-blue bold w100"
          onClick={() => this.updateProfileSetting()}
        >
          Save
        </button>

        {
          loading && <div className="flex justify-center">
            <div className="sweet-loading">
              <ClipLoader color={"#000000"} loading={true} />
            </div>
          </div>
        }
      </div>
    );
  }
}
