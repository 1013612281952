import React from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from "react-infinite-scroller";
import { ClipLoader } from "react-spinners";
import Card from "./Card";
import ProductService from "../services/ProductService";
var mobile = require("is-mobile");

export default class List extends React.Component {
  static propTypes = {
    products: PropTypes.array,
    authToken: PropTypes.string,
  };

  componentDidMount() {
    this.productService = new ProductService();
  }

  render() {
    const { products, isLoading, hasMore, loadMore, deleteTag, styles } = this.props;
    const isMobile = mobile();
    const hasProducts = products.length >= 1;
    const cols = isMobile ? [[], []] : [[], [], []];
    const indexCount = isMobile ? 2 : 3;
    const cssColumns = isMobile ? "c1in2" : "c1in3";

    products.map((product, index) => {
      cols[index % indexCount].push(product);
    });

    return (
      <div>
        {hasProducts && (
          <InfiniteScroll
            pageStart={0}
            isLoading={isLoading}
            loadMore={loadMore}
            hasMore={hasMore && !isLoading}
            className="flex flex-wrap justify-between cell-list masonry"
            useWindow
          >
            {cols.map((col, index) => (
              <div className={`${cssColumns} flex flex-column`} key={index}>
                {col.map((product) => (
                  <Card product={product} deleteTag={deleteTag} styles={styles} />
                ))}
              </div>
            ))}
          </InfiniteScroll>
        )}
        {isLoading && (
          <div className="flex justify-center pb2">
            <div className="bg-white p1" style={{ borderRadius: "40px", display: "inherit" }}>
              <ClipLoader color={"#000000"} loading={isLoading}  />
            </div>
          </div>
        )}
        {!hasProducts && !isLoading && (
          <div className="flex flex-column pt2">
            <div className="flex justify-center">
              <p className="bold">No products</p>
            </div>
            <div className="flex justify-center">
              <p className="max-w350px center-align">
                To create product press the + at the bottom right and tag them
                on your Instagram images.
              </p>
            </div>
          </div>
        )}
      </div>
    );
  }
}
