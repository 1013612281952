export default class LinksService {
  index(authToken, stringParams = "") {
    return fetch(`/api/v1/click_events/links?${stringParams}`, {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        "X-AUTH-TOKEN": authToken,
      }),
    }).then((res) => {
      return res.json();
    });
  }
}