import React, { Component } from "react";

export default class FlashMessage extends Component {
  constructor() {
    super();

    this.state = {
      display: true,
    };

    setTimeout(() => {
      this.setState({ display: false });
    }, 3000);
  }

  render() {
    const { display } = this.state;
    const { type, message } = this.props;

    return (
      <div>{display && <div className={`alert ${type}`}>{message}</div>}</div>
    );
  }
}
