import React from "react";
import Modal from "react-modal";
import ReactCrop from "react-image-crop";
import SimilarProducts from "../products/SimilarProducts";
import TaggableProducts from "../taggable_products/TaggableProducts";
import Card from "../products/Card";
import TaddarVisionService from "../services/TaddarVisionService";
import { ClipLoader } from "react-spinners";
import InstagraProductService from "../services/InstagramPostProductsService";
import MeidaProductService from "../services/MediaProductService";
var mobile = require("is-mobile");
export default class ShopMedia extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: true,
      similarProducts: [],
      isLoading: true,
      hasCropped: false,
      isTagging: false,
      isExploring: false,
      taggedProducts: [],
      isOpen: props.isOpen,
      title: "Select Product",
      isMobile: mobile(),
      crop: {
        unit: "px",
      },
    };

    this.taddarVisionService = new TaddarVisionService();
    this.instagramPostProductService = new InstagraProductService();
    this.meidaProductService = new MeidaProductService();
  }

  componentDidMount() {
    this.loadTaggedProducts();
  }

  loadTaggedProducts = async () => {
    const { media, isProfileUser, user_media_uuid } = this.props;

    this.setState({ isLoading: true });
    const response = await this.meidaProductService.index(
      1,
      media.public_id,
      false,
      user_media_uuid
    );

    const list = response.data.length === 0 ? [] : response.data;

    this.setState({
      taggedProducts: list,
      isLoading: false,
    });

    if (list.length == 0 && !isProfileUser) {
      this.isExploring(media.img_path);
    }
  };

  onChange = (crop) => {
    this.setState({ crop, hasCropped: true, isExploring: true });
  };

  search = () => {
    const { media, authToken } = this.props;
    const { crop } = this.state;
    const formData = new FormData();
    this.setState({ isLoading: true });

    formData.append("cropped_product[crop_x]", crop.x);
    formData.append("cropped_product[crop_y]", crop.y);
    formData.append("cropped_product[crop_w]", crop.width);
    formData.append("cropped_product[crop_h]", crop.height);
    formData.append("cropped_product[instagram_media_id]", media.id);
    formData.append("cropped_product[image]", media.img_path);

    fetch(`/api/v1/cropped_products`, {
      method: "POST",
      body: formData,
      headers: {
        "X-auth-token": authToken,
      },
    }).then((response) => {
      response.json().then((json) => {
        this.findSimilarProducts(json.image_url);
      });
    });
  };

  findSimilarProducts = async (croppedProductUrl) => {
    const { authToken } = this.props;
    const similarProducts = await this.taddarVisionService.similarProducts(
      authToken,
      croppedProductUrl
    );

    this.setState({
      isLoading: false,
      similarProducts: similarProducts,
    });
  };

  tagProduct = () => {
    this.setState({
      isTagging: true,
    });
  };

  resetModal = () => {
    this.setState({
      isTagging: false,
      hasCropped: false,
      similarProducts: [],
      title: "Select Product",
    });

    this.loadTaggedProducts();
  };

  deleteTag = (product) => {
    const { authToken, removeMediaProduct } = this.props;
    const { taggedProducts } = this.state;

    this.instagramPostProductService.destroy(
      product.instagram_post_product_uuid,
      authToken
    );

    this.setState({
      taggedProducts: taggedProducts.filter((p) => p.uuid != product.uuid),
    });

    removeMediaProduct(product);
  };

  updateTaggedProducts = (product) => {
    const { taggedProducts } = this.state;

    this.setState({
      taggedProducts: taggedProducts.concat([product]),
    });
  };

  createProductPage = () => {
    const { user } = this.props;
    window.location = `/${user.username}/products/new`;
  };

  isExploring = (image) => {
    this.setState({
      isExploring: true,
      crop: {
        height: 52.16213378137141,
        width: 40.58454968672336,
        x: 31.08126378242151,
        y: 25.800693058910007,
      },
    });
  };

  isNotExploring = () => {
    this.setState({
      similarProducts: [],
      isExploring: false,
      isLoading: false,
      hasCropped: false,
      crop: {
        unit: "px",
      },
    });
  };

  setTitle = (title) => {
    this.setState({ title });
  };

  modalClose = () => {
    const username = this.props.user.slug;
    this.setState({ isOpen: false });
    this.props.closeModal();
    window.history.pushState({ id: "homepage" }, "ShopMyFeed", `/${username}`);
  };

  render() {
    const {
      closeModal,
      media,
      authToken,
      addMediaProduct,
      isProfileUser,
      user_media_uuid,
    } = this.props;
    const {
      isOpen,
      crop,
      similarProducts,
      isLoading,
      hasCropped,
      isTagging,
      taggedProducts,
      isExploring,
      isMobile,
      title,
    } = this.state;

    const cols = isMobile ? [[], []] : [[], [], []];
    const indexCount = isMobile ? 2 : 3;
    const cssColumns = isMobile ? "c1in2" : "c1in3";

    taggedProducts.map((product, index) => {
      product.index = index;
      cols[index % indexCount].push(product);
    });

    const underLineProducts = isExploring ? "" : "underline-text-black bold";
    const underLineExplore = isExploring ? "underline-text-black bold" : "";

    return (
      <div>
        <Modal
          isOpen={isOpen}
          onRequestClose={this.modalClose}
          className="shop-media-modal max-w500px"
          overlayClassName="shop-media-overlay"
        >
          <div>
            {isTagging && (
              <div>
                <div className="fs">
                  <div className="flex justify-between bg-white u-fixed top-0 z4 w100 max-w500px">
                    <div
                      className="flex items-center p1 pointer"
                      onClick={this.resetModal}
                    >
                      <i className="fa fa-lg fa-chevron-left bg-white pointer pr1 text-grey-xdark" />
                      <h4 className="pl1 pt2 bold">{title}</h4>
                    </div>
                    <div className="flex items-center pr1">
                      <button
                        className="button button-small bg-white button-black-outline mr3"
                        onClick={this.createProductPage}
                      >
                        Create Product
                      </button>

                      <i
                        className="fa fa-lg fa-times text-grey-xdark pointer pr1"
                        onClick={this.modalClose}
                      />
                    </div>
                  </div>
                </div>
                <div className=" min-h100vh">
                  <div className="relative" style={{ top: "53px" }}>
                    <TaggableProducts
                      media={media}
                      resetModel={this.resetModal}
                      user_media_uuid={user_media_uuid}
                      authToken={authToken}
                      updateTaggedProducts={this.updateTaggedProducts}
                      addMediaProduct={addMediaProduct}
                      setTitle={this.setTitle}
                    />
                  </div>
                </div>
              </div>
            )}

            {!isTagging && (
              <div>
                <div className="flex justify-between">
                  <div className="flex items-center p2">
                    <img
                      className="medium-avatar mx2"
                      src={media.user_avatar}
                    />
                    <h4 className="pt2 bold">{media.user_name}</h4>
                  </div>
                  <div className="flex items-center pr3">
                    <i
                      className="fa fa-lg fa-times text-grey-xdark pointer pr1"
                      onClick={this.modalClose}
                    />
                  </div>
                </div>
                <div>
                  <div className="flex bg-grey-xxlight">
                    <div className="relative">
                      {!isExploring &&
                        taggedProducts.map((taggedProduct, index) => (
                          <div
                            className={`absolute z2 dot-number-white`}
                            style={{
                              display: "inline-block",
                              top: `calc(${taggedProduct.coordinates.top}% - 8px )`,
                              left: `calc(${taggedProduct.coordinates.left}% - 8px)`,
                              paddingTop: '2px',
                              boxShadow: "rgb(149 149 149 / 65%) 0px 0px 1px 9px",
                            }}
                          >
                            {index + 1}
                          </div>
                        ))}

                      {!isExploring && (
                        <img
                          className="ReactCrop__image max-w600px z1 image-fit pb1"
                          src={media.img_path}
                        />
                      )}

                      {isExploring && (
                        <ReactCrop
                          src={media.img_path}
                          crop={crop}
                          onChange={this.onChange}
                          className="ReactCrop__image max-w600px z3"
                        />
                      )}
                    </div>
                  </div>
                  <div className="flex justify-between my2">
                    <div className="flex pl2 relative" style={{ top: "4px" }}>
                      <p
                        className={`${underLineProducts}`}
                        onClick={this.isNotExploring}
                      >
                        Products
                      </p>
                      <p
                        className={`ml2 ${underLineExplore}`}
                        onClick={() => this.isExploring(media.img_path)}
                      >
                        Explore
                      </p>
                    </div>

                    {isProfileUser && !isExploring && (
                      <button
                        className="button button-small btn-purple h100 mr2"
                        onClick={this.tagProduct}
                      >
                        Tag product
                      </button>
                    )}

                    {isExploring && (
                      <button
                        onClick={this.search}
                        className="button btn-purple button-small h100 mr2"
                      >
                        Search
                      </button>
                    )}
                  </div>

                  {isExploring && similarProducts.length == 0 && (
                    <div className="flex flex-column justify-center justify-baseline px2">
                      <h2 className="bold mb0 center-align">Shop Similar</h2>
                      <div className="flex justify-center">
                        <p className="max-w250px center-align">
                          Adjust crop around one item you want to search, then
                          press Search.
                        </p>
                      </div>
                    </div>
                  )}

                  {isLoading && (
                    <div className="pt4 sweet-loading center-align">
                      <ClipLoader color={"#000000"} loading={true} />
                    </div>
                  )}

                  {taggedProducts.length == 0 &&
                    !isLoading &&
                    !hasCropped &&
                    !isExploring && (
                      <div className="sweet-loading center-align px2">
                        {isProfileUser && !isExploring && (
                          <div>
                            <p className="text-grey-xdark bold">
                              Add a tagged product
                            </p>
                            <p className="text-grey-xdark">
                              Press the purple Tag Product button to begin
                            </p>
                          </div>
                        )}
                        {!isProfileUser && !isExploring && (
                          <div>
                            <p className="text-grey-xdark bold">
                              No Tagged products
                            </p>
                            <p className="text-grey-xdark">
                              Press explore to view similar products
                            </p>
                          </div>
                        )}
                      </div>
                    )}

                  {!hasCropped && !isExploring && (
                    <div className="flex flex-wrap justify-between cell-list masonry">
                      {cols.map((col, indexColumn) => (
                        <div
                          className={`${cssColumns} flex flex-column`}
                          key={indexColumn}
                        >
                          {col.map((product, index) => (
                            <Card
                              product={product}
                              authToken={authToken}
                              deleteTag={isProfileUser && this.deleteTag}
                              index={product.index + 1}
                            />
                          ))}
                        </div>
                      ))}
                    </div>
                  )}

                  <SimilarProducts products={similarProducts} />
                </div>
              </div>
            )}
          </div>
        </Modal>
      </div>
    );
  }
}
