import React, { Component } from "react";
import { PropTypes } from "prop-types";
import Modal from "react-modal";
import ShopMedia from "./ShopMedia";

export default class Media extends React.Component {
  static propTypes = {
    media: PropTypes.object,
    user: PropTypes.object,
    authToken: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false,
      media: props.media,
      products: props.media.products,
    };
  }

  openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  closeModal = () => {
    const { user } = this.props;
    this.setState({ modalIsOpen: false });
    window.history.pushState({ id: "homepage" }, "Taddar", `/${user.slug}`);
  };

  addMediaProduct = (product) => {
    const { products } = this.state;

    const list = products.concat(product);
    this.setState({ products: list });
  };

  removeMediaProduct = (product) => {
    const { products } = this.state;

    this.setState({
      products: products.filter((p) => p.uuid != product.uuid),
    });
  };

  render() {
    const {
      user,
      authToken,
      isProfileUser,
      user_media_uuid,
      openModal,
    } = this.props;
    const { modalIsOpen, media, products } = this.state;

    return (
      <div>
        {(modalIsOpen || openModal) && (
          <ShopMedia
            user={user}
            isOpen={modalIsOpen || openModal}
            closeModal={() => this.closeModal()}
            media={media}
            authToken={authToken}
            addMediaProduct={this.addMediaProduct}
            removeMediaProduct={this.removeMediaProduct}
            isProfileUser={isProfileUser}
            user_media_uuid={user_media_uuid}
          />
        )}
        <div className="card-image-container relative spec-instagram-image">
          <div className="card-image">
            <div className="relative">
              {products.map((product, index) => (
                <div
                  className={`absolute z1 dot-number-white-small`}
                  style={{
                    display: "inline-block",
                    top: `calc(${product.top}% - 8px )`,
                    left: `calc(${product.left}% - 8px)`,
                    boxShadow: "rgb(149 149 149 / 65%) 0px 0px 1px 9px",
                  }}
                ></div>
              ))}
              <img
                className="w100 pl1 pr0.5"
                onClick={this.openModal}
                src={media.img_path}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
