import React, { Component } from "react";
import Analytics from "../products/Analytics";
import ClickEventService from "../services/ClickEventService";
import LinksService from "../services/click_events/LinksService";
import ProductsService from "../services/click_events/ProductsService";
import { ClipLoader } from "react-spinners";

export default class ClickAnalytics extends Component {
  constructor(props) {
    super(props)

    this.state = {
      analytics: [],
      linksActive: true,
      loading: true,
      productsActive: false,
      displayNavigation: true
    }

    this.clickEventService = new ClickEventService()
    this.linksService = new LinksService()
    this.productsService = new ProductsService()

    const queryString = window.location.search

    if(queryString.includes("link_uuid")) {
      this.linksActive();
    } else if (queryString.includes("product_uuid")) {
      this.productsActive();
    } else {
      this.linksActive();
    }
  }
  
  async linksActive() {
    const { auth_token, link } = this.props;

    this.setState({ linksActive: true, loading: true, productsActive: false, analytics: [] })

    const queryString = window.location.search

    if(queryString.includes("link_uuid")) {
      const linkUuid = queryString.replace("?link_uuid=", "")
      const res = await this.linksService.index(auth_token, `link_uuid=${linkUuid}`);
      this.setState({ analytics: res, displayNavigation: false, loading: false })
    } else {
      const res = await this.linksService.index(auth_token);
      this.setState({ analytics: res, loading: false })
    }
  }

  async productsActive() {
    const { auth_token } = this.props;
    
    this.setState({ linksActive: false, productsActive: true, analytics: [], loading: true })

    const queryString = window.location.search

    if(queryString.includes("product_uuid")) {
      const productUuid = queryString.replace("?product_uuid=", "")
      const res = await this.productsService.index(auth_token, `product_uuid=${productUuid}`);
      this.setState({ analytics: res, displayNavigation: false, loading: false })
    } else {
      const res = await this.productsService.index(auth_token);
      this.setState({ analytics: res, loading: false })
    }
  }

  render() {
    const { analytics, linksActive, productsActive, displayNavigation, loading} = this.state;

    const linksStyle = linksActive ?  { borderBottom: "3px solid" } : {}
    const productsStyle = productsActive ? { borderBottom: "3px solid" } : {}

    return (
      <div>
        {
          displayNavigation && <div className="flex">
            <p className="bold p2" style={linksStyle} onClick={() => this.linksActive()}>Links</p>
            <p className="p2" style={productsStyle} onClick={() => this.productsActive()}>Products</p>
          </div>
        }

        {
          !displayNavigation && <p className="bold">Analytics for: {analytics.name}</p>
        }

        <h3 className="bold">Lifetime total clicks: {analytics.total_count}</h3>

        {
          loading && <div className="flex justify-center">
            <div className="sweet-loading">
              <ClipLoader color={"#000000"} loading={true} />
            </div>
          </div>
        }

        {
          analytics.length != 0 && <div>
            <Analytics series={analytics}/>
          </div>
        }
      </div>
    )
  }
}