import React, { Component } from "react";
import { PropTypes } from "prop-types";
import ReactCrop from "react-image-crop";

export default class RoiSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isCropping: false
    };
  }

  onChange = crop => {
    this.setState({ crop, isCropping: true }, this.onCropChange);
  };

  onCropChange = crop => {
    if (this.timeOut) {
      window.clearTimeout(this.timeOut);
    }
    this.timeOut = window.setTimeout(() => {
      this.props.onCrop(this.state.crop);
    }, 300);
  };

  render() {
    const { imageUrl, showCropProductMessage } = this.props;
    const { crop, isCropping } = this.state;

    return (
      <div className="rounded">
        {(!isCropping || showCropProductMessage) && (
          <div className="flex justify-center">
            <div
              className="absolute z1 bg-grey-xdark rounded px2 m1"
              style={{ opacity: "0.8" }}
            >
              <div className="flex items-center">
                <i className="fa fa-lg fa-crop text-white pointer pr2" />
                <h5 className="white bold pt2">Crop to explore outfit</h5>
              </div>
            </div>
          </div>
        )}
        <ReactCrop
          src={imageUrl}
          onChange={this.onChange}
          crop={crop}
          style={{ borderRadius: "10px", width: "auto", height: "auto" }}
          className="border-full border-black"
        />
      </div>
    );
  }
}
