import React, { Component } from "react";
import LinksService from "../services/LinksService"
import ClickEventService from "../services/ClickEventService"
import Edit from "./Edit";

export default class Link extends React.Component {
  constructor(props) {
    super(props);

    this.state =  {
      url: props.link.url,
      showNewForm: false,
      showEditForm: false
    }

    this.linksService = new LinksService()
    this.clickEventService = new ClickEventService()
  }

  redirect = (link) => {
    this.clickEventService.create({ link_uuid: link.uuid })
    window.location = link.url
  }

  destroyLink = async () => {
    const { link } = this.props;
    const { authToken, updateLinkList } = this.props;
    
     await this.linksService.destroy(link.uuid, authToken);
    
     updateLinkList(link)
  }

  toggleMenu() {
    const { showMenu } = this.state;

    this.setState({ showMenu: !showMenu });
  }

  editLink() {
    this.setState({ showMenu: false })
    this.toggleEditForm()
  }

  toggleEditForm = () =>  {
    const { showEditForm } = this.state;

    this.setState({ showEditForm: !showEditForm });
  }

  updateLink = (link) => {
    this.props.link.name = link.name
    this.props.link.url = link.url
  }

  redirectToAnalytics() {
    const { link } = this.props;
    window.location = `/analytics?link_uuid=${link.uuid}`
  }

  render() {
    const { showMenu, showEditForm, name } = this.state;
    const { styles, link, authToken } = this.props;

    return (
      <div>
        <div 
          className="relative button button-larger mb2 words-in-div w100" 
          style={ styles.button_css }
        >
          {
            authToken &&
            <div
              className="absolute z2 pr1 pt1 pointer test-edit-link"
              style={{right: "3px", paddingTop: "2px"}}
              onClick={() => this.toggleMenu()}
            >
              <div
                className="bg-white border-full border-grey"
                style={{
                  width: "27px",
                  height: "27px",
                  borderRadius: "20px",
                }}
              >
                <i
                  className="fa fa-ellipsis-v text-grey-xdark pointer"
                  style={{ paddingTop: "4px" }}
                />
              </div>
            </div>
          }
          <div onClick={() => this.redirect(link)}>
            {link.name}
          </div>
        </div>
        {showMenu && (
          <div className="relative z3">
            <div className="absolute right-0" style={{ top: "-29px" }}>
              <div
                className="bg-white rounded py2 px4 z3 border-full border-grey mb4 w100px mr1"
              >
                <p className="text-black pointer" onClick={() => this.redirectToAnalytics()}>
                  Analytics
                </p>
                <p className="text-black pointer" onClick={() => this.editLink()}>
                  Edit
                </p>
                <p className="text-black pointer" onClick={() => this.destroyLink()}>
                  Delete
                </p>
              </div>
            </div>

          </div>
        )}
        {
          showEditForm && <Edit link={link} authToken={authToken} toggleEditForm={this.toggleEditForm} updateLink={this.updateLink}/>
        }
      </div>
    )
  }
}