import React, { Component } from "react";

export default class SetProfileSettingsButton extends Component {
  setProfileSettings() {
    const { auth_token, profile_setting_prototype_uuid } = this.props;

    return fetch(`/api/v1/profile_settings_copy/${profile_setting_prototype_uuid}`, {
      method: 'PATCH',
      headers: new Headers({
        'Content-Type': 'application/json',
        'X-AUTH-TOKEN': auth_token,
      })
    })
      .then(res => {
        return res.json();
      })
  };

  render() {
    return (
      <div>
        <button className="button button-black" onClick={() => this.setProfileSettings()}>Set Settings</button>
      </div>
    );
  }
}
