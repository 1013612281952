import React, { Component } from "react";
import ProfileSetting from "../services/ProfileSetting";
import { ClipLoader } from "react-spinners";
import ColorPicker from "../shared/ColorPicker";
import Slider from 'react-rangeslider';
import store from "../store";
import produce from "immer"

export default class ProfileButtons extends Component {
  constructor(props) {
    super(props)

    const styles = store.getState().styles
    const boxShadow = styles.button_css.boxShadow
    const border = styles.button_css.border

    this.state = {
      styles: styles,
      buttonCss: styles.button_css,
      loading: false,
      isOpen: false,
      colorPickerTarget: "",
      colorPickerColor: "",
      showButtonAttributes: false,
      borderColor: border && border.split("solid ")[1] || "#848484",
      boxShadowColor: boxShadow && boxShadow.split(" ")[0] || "#848484",
      shadowHorizontal: boxShadow && boxShadow.split(" ")[1].split("px")[0] || 5,
      shadowVerticle: boxShadow && boxShadow.split(" ")[2].split("px")[0] || 5,
      shadowBlur: boxShadow && boxShadow.split(" ")[3].split("px")[0] || 5,
      boxShadow: boxShadow || "#848484 5px 5px 4px",
      borderRadius: styles.button_css.borderRadius || "5px",
      border: styles.button_css.border || "1px solid #3b3b3b",
      backgroundColor: styles.button_css.backgroundColor || "black",
    }

    store.subscribe(() =>
      this.setState({ styles: store.getState().styles })
    )

    this.profileSettingService = new ProfileSetting();
  }

  setColor(target, hexColor) {
    this.setState({
      isOpen: true,
      colorPickerTarget: target,
      colorPickerColor: hexColor,
    });
  }

  handeColorPickerChange = async (target, val) => {
    const { updateReduxState } = this.props;
    const { shadowHorizontal, shadowVerticle, shadowBlur } = this.state;
    const styles = store.getState().styles
    const buttonCss = styles.button_css

    if (target == "borderColor") {
      const value = buttonCss.border == undefined ? 1 : buttonCss.border.split("px")[0]
      const nextState = produce(buttonCss, draftState => {
        draftState.border = `${value}px solid ${val}`
      })

      updateReduxState("button_css", nextState)
    } else if (target == "boxShadow") {
      const nextState = produce(buttonCss, draftState => {
        draftState.boxShadow = `${val} ${shadowHorizontal}px ${shadowVerticle}px ${shadowBlur}px`
      })

      updateReduxState("button_css", nextState)
    } else {
      const nextState = produce(buttonCss, draftState => {
        draftState[target] = val
      })


      updateReduxState("button_css", nextState)
    }
  };

  boxShadow(type, value) {
    const { boxShadowColor, shadowHorizontal, shadowVerticle, shadowBlur } = this.state;

    if (type == "shadowHorizontal") {
      const cssAttribute = { boxShadow: `${boxShadowColor} ${value}px ${shadowVerticle}px ${shadowBlur}px`, marginBottom: "2rem" }
      this.setState({ shadowHorizontal: value })
      return cssAttribute
    }

    if (type == "shadowVerticle") {
      const cssAttribute = { boxShadow: `${boxShadowColor} ${shadowHorizontal}px ${value}px ${shadowBlur}px`, marginBottom: "2rem" }
      this.setState({ shadowVerticle: value })
      return cssAttribute
    }

    if (type == "shadowBlur") {
      const cssAttribute = { boxShadow: `${boxShadowColor} ${shadowHorizontal}px ${shadowVerticle}px ${value}px`, marginBottom: "2rem" }
      this.setState({ shadowBlur: value })
      return cssAttribute
    }
  }

  borderRadius(value = 0) {
    const cssAttribute = { borderRadius: `${value}px` }
    this.setState(cssAttribute)
    return cssAttribute
  }

  border(value = 1) {
    const { borderColor } = this.state;
    const cssAttribute = { border: `${value}px solid ${borderColor}` }
    this.setState(cssAttribute)
    return cssAttribute
  }

  handleChecking = async (event) => {
    const { boxShadow, borderRadius, border } = this.state;
    const target = event.target.id
    const checked = event.target.checked

    if (target == "border") {
      this.setButton(checked, { border }, "border")
    }

    if (target == "borderRadius") {
      this.setButton(checked, { borderRadius }, "borderRadius")
    }

    if (target == "boxShadow") {
      this.setButton(checked, { boxShadow }, "boxShadow")
    }
  }

  setButton(checked, cssAttributes, attribute) {
    const styles = store.getState().styles
    const buttonCss = styles.button_css
    const { updateReduxState } = this.props;

    if (checked) {
      // const  css = { ...buttonCss, ...cssAttributes }
      const nextState = produce(buttonCss, draftState => {
        draftState[attribute] = cssAttributes[attribute]
      })

      updateReduxState("button_css", nextState);
    } else {
      const nextState = produce(buttonCss, draftState => {
        delete draftState[attribute]
      })

      updateReduxState("button_css", nextState);
    }
  }

  async updateProfileSetting() {
    const { authToken, updateReduxState } = this.props;
    const styles = store.getState().styles
    const buttonCss = styles.button_css
    const params = {
      profile_setting: styles,
      uuid: styles.uuid
    };

    this.setState({ loading: true })
    const response = await this.profileSettingService.update(authToken, params);

    const nextState = produce(buttonCss, draftState => {
      draftState = response
    })
    updateReduxState("button_css", nextState);

    this.setState({ hasSaved: true, loading: false })
    setTimeout(() => {
      this.setState({ hasSaved: false })
    }, 2000);
  }

  closeModal = () => {
    this.setState({ isOpen: false });
  };

  sliderChange = (type, value) => {
    if (type == "borderSize") {
      this.setButton(true, this.border(value), "border")
    } else if (type == "borderRadius") {
      this.setButton(true, this.borderRadius(value), "borderRadius")
    } else if (type == "shadowHorizontal") {
      this.setButton(true, this.boxShadow(type, value), "boxShadow")
    } else if (type == "shadowVerticle") {
      this.setButton(true, this.boxShadow(type, value), "boxShadow")
    } else if (type == "shadowBlur") {
      this.setButton(true, this.boxShadow(type, value), "boxShadow")
    }
  }

  handleCustom(value) {
    const { updateReduxState } = this.props;

    updateReduxState("button_css", value)
  }

  addCssField() {
    const { updateReduxState } = this.props;
    const styles = store.getState().styles
    const buttonCss = styles.button_css

    const nextState = produce(buttonCss, draftState => {
      draftState[`attribute ${Object.keys(buttonCss).length}`] = "value"
    })

    updateReduxState("button_css", nextState);
  }

  customCssKey(key, value) {
    const styles = store.getState().styles
    const buttonCss = styles.button_css
    const { updateReduxState } = this.props;

    const nextState = produce(buttonCss, draftState => {
      const currentValue = draftState[key]
      delete draftState[key]
      draftState[value] = currentValue
    })

    updateReduxState("button_css", nextState);
  }

  customCssValue(key, value) {
    const styles = store.getState().styles
    const buttonCss = styles.button_css
    const { updateReduxState } = this.props;


    const nextState = produce(buttonCss, draftState => {
      draftState[key] = value
    })

    updateReduxState("button_css", nextState)
  }

  showButtonAttributes() {
    const { showButtonAttributes } = this.state

    this.setState({ showButtonAttributes: !showButtonAttributes })
  }

  removeCustomAttribute(key) {
    const styles = store.getState().styles
    const buttonCss = styles.button_css
    const { updateReduxState } = this.props;

    const nextState = produce(buttonCss, draftState => {
      delete draftState[key]
    })

    updateReduxState("button_css", nextState)
  }

  render() {
    const { loading, isOpen, colorPickerTarget, colorPickerColor, showButtonAttributes } = this.state;

    const styles = store.getState().styles
    const buttonCss = styles.button_css
    const borderColor = buttonCss.border && buttonCss.border.split("solid ")[1] || "#848484"
    const borderWidth = buttonCss.border == undefined ? 1 : buttonCss.border.split("px")[0]
    const radiusCorner = buttonCss.borderRadius == undefined ? 0 : buttonCss.borderRadius.split("px")[0]
    const horizontalShadow = buttonCss.boxShadow == undefined ? 1 : buttonCss.boxShadow.split(" ")[1].split("px")[0]
    const verticleShadow = buttonCss.boxShadow == undefined ? 1 : buttonCss.boxShadow.split(" ")[2].split("px")[0]
    const shadowBlur = buttonCss.boxShadow == undefined ? 1 : buttonCss.boxShadow.split(" ")[3].split("px")[0]
    const boxShadowColor = buttonCss.boxShadow && buttonCss.boxShadow.split(" ")[0] || "#848484"
    
    return (
      <div className="container-no-padding max-w800px bg-white rounded p3 rounded font-small">
        <ColorPicker
          isOpen={isOpen}
          handleChange={this.handeColorPickerChange}
          close={this.closeModal}
          target={colorPickerTarget}
          color={colorPickerColor}
        />

        <div className="my2">
          <div className="flex items-center mb2">
            <p className="mb0 bold">Background Color</p>
          </div>
          <div className="flex">
            <div className="w40">
              <p className="mb0">Colour</p>
            </div>
            <div className="w60">
              <div
                className="rounded ml1 border-full border-grey-dark"
                onClick={() => this.setColor("backgroundColor", styles.button_css.backgroundColor)}
                style={{
                  backgroundColor: styles.button_css.backgroundColor,
                  width: "60px",
                  height: "30px",
                }}
              ></div>
            </div>
          </div>
        </div>


        <div className="my2">
          <div className="flex items-center mb2">
            <p className="mb0 bold">Font Color</p>
          </div>
          <div className="flex">
            <div className="w40">
              <p className="mb0">Colour</p>
            </div>
            <div className="w60">
              <div
                className="rounded ml1 border-full border-grey-dark"
                onClick={() => this.setColor("color", styles.button_css.color)}
                style={{
                  backgroundColor: styles.button_css.color,
                  width: "60px",
                  height: "30px",
                }}
              />
            </div>
          </div>
        </div>


        <div className="my2">
          <div className="flex items-center mb2">
            <input type="checkbox" id="border" checked={buttonCss.border != undefined && buttonCss.border.includes("px solid")} style={{ marginBottom: "1px" }} onChange={(event) => this.handleChecking(event)} />
            <p className="mb0 bold">Border</p>
          </div>
          <div className="flex">
            <div className="w40">
              <p className="mb0">Colour</p>
            </div>
            <div className="w60">
              <div
                className="rounded ml1 border-full border-grey-dark"
                onClick={() => this.setColor("borderColor", borderColor)}
                style={{
                  backgroundColor: borderColor,
                  width: "60px",
                  height: "30px",
                }}
              />
            </div>
          </div>
          <div className="flex items-center">
            <div className="w40">
              <p className="mb0">Width</p>
            </div>
            <div className="flex w60 items-center">
              <p className="mb0 pr1">{borderWidth}px</p>
              <Slider
                className="ml1 w150px"
                value={borderWidth}
                min={1}
                max={10}
                onChange={e => this.sliderChange("borderSize", e)}
              />
            </div>
          </div>
        </div>

        <div className="my2">
          <div className="flex items-center">
            <input type="checkbox" id="borderRadius" checked={buttonCss.borderRadius != undefined && buttonCss.borderRadius.includes("px")} style={{ marginBottom: "1px" }} onChange={(event) => this.handleChecking(event)} />
            <p className="mb0 bold">Corner radius</p>
          </div>

          <div className="flex items-center">
            <div className="w40">
              <p className="mb0">Radius</p>
            </div>
            <div className="flex w60 items-center">
              <p className="mb0 pr1">{radiusCorner}px</p>
              <Slider
                className="ml1 w150px"
                value={radiusCorner}
                min={0}
                max={40}
                onChange={e => this.sliderChange("borderRadius", e)}
              />
            </div>
          </div>
        </div>

        <div className="my2">
          <div className="flex items-center mb2">
            <input type="checkbox" id="boxShadow" checked={buttonCss.boxShadow != undefined} style={{ marginBottom: "1px" }} onChange={(event) => this.handleChecking(event)} />
            <p className="mb0 bold">Shadow</p>
          </div>

          <div className="flex items-center">
            <div className="w40">
              <p className="mb0">Colour</p>
            </div>
            <div className="w60">
              <div
                className="rounded border-full border-grey-dark"
                onClick={() => this.setColor("boxShadow", boxShadowColor)}
                style={{
                  backgroundColor: boxShadowColor,
                  width: "60px",
                  height: "30px",
                }}
              />
            </div>
          </div>

          <div className="flex items-center">
            <div className="w40">
              <p className="mb0">Horizontal</p>
            </div>
            <div className="flex w60 items-center">
              <p className="mb0 pr1">{horizontalShadow}px</p>
              <Slider
                className="ml1 w150px"
                value={horizontalShadow}
                min={-10}
                max={10}
                onChange={e => this.sliderChange("shadowHorizontal", e)}
              />
            </div>
          </div>

          <div className="flex items-center">
            <div className="w40">
              <p className="mb0">Verticle</p>
            </div>
            <div className="flex w60 items-center">
              <p className="mb0 pr1">{verticleShadow}px</p>
              <Slider
                className="ml1 w150px"
                value={verticleShadow}
                min={-10}
                max={10}
                onChange={e => this.sliderChange("shadowVerticle", e)}
              />
            </div>
          </div>

          <div className="flex items-center">
            <div className="w40">
              <p className="mb0">Spread</p>
            </div>
            <div className="flex w60 items-center">
              <p className="mb0 pr1">{shadowBlur}px</p>
              <Slider
                className="ml1 w150px"
                value={shadowBlur}
                min={0}
                max={10}
                onChange={e => this.sliderChange("shadowBlur", e)}
              />
            </div>
          </div>
        </div>

        <div className="flex mt2">
          <input type="checkbox" id="showButtonAttributes" checked={showButtonAttributes} onChange={() => this.showButtonAttributes()} />
          <p className="mb0 bold mr2">Customize Attributes</p>
        </div>

        {
          showButtonAttributes && <div >
            <p>Create any button you want by adding CSS attributes below.</p>
            {
              Object.keys(buttonCss).map(key =>
                <div className="flex">
                  <input
                    className="mr1 w50"
                    type="text"
                    value={key}
                    onChange={(event) => this.customCssKey(key, event.target.value)}
                  />
                  <input
                    className="w50"
                    type="text"
                    value={buttonCss[key]}
                    onChange={(event) => this.customCssValue(key, event.target.value)}
                  />
                  <i
                    className="fa fa-lg pl2 pt3 fa-times text-red pointer"
                    onClick={(event) => this.removeCustomAttribute(key)}
                  />
                </div>
              )
            }

            <div className="flex justify-center items-center mt1">
              <div onClick={() => this.addCssField()}>
                <i
                  className="fa fa-lg fa-plus p2 mr2"
                  style={{ borderRadius: "30px", paddingTop: "11px", backgroundColor: "green", color: "white" }}
                />
                <span>Add Field</span>
              </div>
            </div>
          </div>
        }

        <button
          className="button button-blue bold w100 mt2"
          onClick={() => this.updateProfileSetting()}
        >
          Save
        </button>

        {
          loading && <div className="flex justify-center">
            <div className="sweet-loading">
              <ClipLoader color={"#000000"} loading={true} />
            </div>
          </div>
        }
      </div>
    );
  }
}