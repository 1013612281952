import React, { Component } from "react";
import LinksService from "../services/LinksService";

export default class Edit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: props.link.name,
      url: props.link.url
    };

    this.linksService = new LinksService();
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  handleSave = async () => {
    const { name, url } = this.state
    const { authToken, toggleEditForm, link, updateLink } = this.props;

    const params = {
      link: {
        name: name, 
        url: url,
        uuid: link.uuid
      }
    }

    const res = await this.linksService.update(params, authToken)

    updateLink(res)
    toggleEditForm()
  }

  render() {
    const { toggleEditForm } = this.props;
    const { name, url } = this.state;

    return (
      <div className="rounded p4 bg-white text-black mb2" style={{border: "dashed #c1c1c1 2px"}}>
        <div className="flex justify-between">
          <p className="mb0">Title</p>
          <i 
            className="fa fa-times" 
            onClick={() => toggleEditForm()}
          />
        </div>
        <input
          type="text"
          style={{ width: "100%" }}
          name="name"
          value={name}
          onChange={this.handleInputChange}
        />
        <p className="mb0">Url</p>
        <input
          type="text"
          style={{ width: "100%" }}
          name="url"
          value={url}
          onChange={this.handleInputChange}
        />
        <button
          className="button button-black button-small w100"
          onClick={this.handleSave}
        >
          Save
        </button>
      </div>
    )
  }
}
