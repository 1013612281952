import { configureStore } from '@reduxjs/toolkit';
const initialState = {};

export default configureStore({
  reducer: {
    styles: counterReducer,
    profileSettingPrototypes: profileSettingPrototypesReducer
  },
});

function counterReducer(state = initialState, action) {
  // Check to see if the reducer cares about this action
  if (action.type === 'counter/incremented') {
    // If so, make a copy of `state`
    return {
      ...state,
      // and update the copy with the new value
      ...action.payload
    };
  }
  // otherwise return the existing state unchanged
  return state
}

function profileSettingPrototypesReducer(state = [], action) {
  if (action.type === 'profileSettingPrototypes') {
    return action.payload || state
  }
  return state
}
