export default class ClickEventService {
  create(params) {
    return fetch("/api/v1/click_events", {
      method: "POST",
      body: JSON.stringify(params),
      headers: new Headers({
        "Content-Type": "application/json",
      }),
    }).then((res) => {
      return res.json();
    });
  }
}
