export default class MonetizeUrlService {
  index(url, authToken) {
    return fetch(`/api/v1/monetize_urls?url=${url}`, {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        "X-AUTH-TOKEN": authToken,
      }),
    }).then((res) => {
      return res.json();
    });
  }
}
