import React, { Component } from "react";
import { PropTypes } from "prop-types";
import Dropzone from "react-dropzone";
import { PulseLoader } from "react-spinners";
import ImageOfInterestService from "../services/ImageOfInterestService";

export default class ImageDashboardUploader extends Component {
  static propTypes = {
    auth_token: PropTypes.string
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false
    };

    this.imageOfInterestService = new ImageOfInterestService();
  }

  onDrop = async acceptedFiles => {
    const { auth_token } = this.props;
    const formData = new FormData();
    formData.append("image_of_interest[image]", acceptedFiles[0]);
    this.setState({ isLoading: true });

    fetch("/api/v1/image_of_interests", {
      method: "POST",
      body: formData,
      headers: {
        "X-auth-token": auth_token
      }
    }).then(response => {
      response.json().then(json => {
        const { id, url } = json;
        this.setState(
          {
            id,
            url,
            isLoading: false
          },
          location.reload()
        );
      });
    });
  };

  render() {
    const { isLoading } = this.state;

    return (
      <div>
        <Dropzone
          onDrop={this.onDrop}
          style={{
            backgroundColor: "black",
            borderRadius: "40px",
            width: "80px",
            height: "80px",
            cursor: "pointer"
          }}
        >
          <div className="justify-center">
            {isLoading ? (
              <div className="pt6 center-align">
                <PulseLoader color={"white"} loading={isLoading} />
              </div>
            ) : (
              <i
                className="white block fa fa-3x fa-upload"
                style={{
                  paddingTop: "16px",
                  paddingLeft: "20px"
                }}
              />
            )}
          </div>
        </Dropzone>
      </div>
    );
  }
}
