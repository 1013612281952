import React from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from "react-infinite-scroller";
import { ClipLoader } from "react-spinners";
import InstagramPostService from "../services/users/InstagramPostService";
import Media from "./Media";
var mobile = require("is-mobile");

export default class Medias extends React.Component {
  static propTypes = {
    medias: PropTypes.array,
    user: PropTypes.object,
  };

  constructor(props) {
    super(props);
    
    this.state = {
      medias: [],
      mediaIds: [],
      nextMaxId: null,
      displayRecconnect: false,
      hasMore: true,
      loading: false,
      isMobile: mobile(),
    };

    this.instagramPostService = new InstagramPostService();
  }

  loadMedias = async () => {
    const { authToken, user } = this.props;
    const { medias, mediaIds, nextMaxId } = this.state;

    this.setState({ loading: true });

    const response = await this.instagramPostService.index(
      nextMaxId,
      authToken,
      user
    );

    if(response.error == undefined) {
      this.setState({
        medias: medias.concat(
          response.medias.filter((media) => mediaIds.indexOf(media.media_id) < 0)
          ),
          nextMaxId: response.next_max_id,
          hasMore: response.has_more,
          loading: false
        });
      } else {
      this.setState({displayRecconnect: true, loading: false, hasMore: false })
    }
  };

  render() {
    const { medias, hasMore, loading, isMobile, displayRecconnect } = this.state;
    const { user, authToken, isProfileUser, user_media_uuid, instagram_login_url } = this.props;

    console.log("medias", medias);
    const cols = isMobile ? [[], []] : [[], [], []];
    const indexCount = isMobile ? 2 : 3;
    const cssColumns = isMobile ? "c1in2" : "c1in3";

    medias.map((media, index) => {
      cols[index % indexCount].push(media);
    });

    return (
      <div className="container-1010 max-w800px">
        <InfiniteScroll
          pageStart={0}
          loadMore={this.loadMedias}
          hasMore={hasMore && !loading}
          className="flex justify-between cell-list masonry pr1"
        >
          {cols.map((col, index) => (
            <div className={`${cssColumns} flex flex-column`} key={index}>
              {col.map((media) => (
                <Media
                  key={media.media_id}
                  media={media}
                  user={user}
                  authToken={authToken}
                  user_media_uuid={user_media_uuid}
                  isProfileUser={isProfileUser}
                />
              ))}
            </div>
          ))}
        </InfiniteScroll>

        {
          !user.instagram_token_expiry && isProfileUser && <div className="flex justify-center">
            <div className="bg-white rounded p2 border-full border-grey mt4" >
              <a href={instagram_login_url} >
                <p>Create a shoppable Instagram feed</p>
                <div className="flex justify-center">
                  <button className="button button-black">Connect Instagram</button>
                </div>
              </a>
            </div>
          </div>
        }

        {
          displayRecconnect && user.instagram_token_expiry && isProfileUser && <div className="flex justify-center">
            <div className="bg-white rounded p2 border-full border-grey" >
              <a href={instagram_login_url} >
                <p>There was an error please reconnect Instagram</p>
                <div className="flex justify-center">
                  <button className="button button-black">Connect Instagram</button>
                </div>
              </a>
            </div>
          </div>
        }

        {
          !displayRecconnect && medias.length == 0 && isProfileUser && !loading && user.instagram_token_expiry && <div className="flex justify-center">
            <div className="bg-white rounded p2 border-full border-grey mt4 mx2" >
              <div className="flex justify-center bold">
                <p>No Shoppable Images</p>
              </div>

              <p>You have no images on instagram or there was an error connecting. Please contact support.</p>

              <a href={instagram_login_url} >
                <div className="flex justify-center">
                  <button className="button button-black">Reconnect Instagram</button>
                </div>
              </a>
            </div>
          </div>
        }

        {
          medias.length == 0 && !isProfileUser && !loading && <div className="flex justify-center">
            <div className="bg-white rounded p2 border-full border-grey mt4" >
              <div className="flex justify-center">
                <span>No Shoppable Images</span>
              </div>
            </div>
          </div>
        }

        { loading && (
          <div className="flex justify-center pb2">
            <div className="bg-white p1" style={{ borderRadius: "40px", display: "inherit" }}>
              <ClipLoader color={"#000000"} loading={loading}  />
            </div>
          </div>
        )}
      </div>
    );
  }
}
