import React, { Component } from "react";
import { PropTypes } from "prop-types";
import LinksService from "../services/LinksService";

export default class New extends React.Component {
  constructor() {
    super();

    this.state = {};

    this.linksService = new LinksService();
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  handleSave = async () => {
    const { name, url } = this.state
    const { authToken, toggleNewForm, addLinkToList } = this.props;

    const params = {
      name: name, 
      url: url
    }

    const res = await this.linksService.create(params, authToken)

    addLinkToList(res)
    toggleNewForm()
  }

  render() {
    const { toggleNewForm } = this.props;

    return (
      <div className="rounded p4 bg-white text-black relative" onClick={() => this.redirect(link)} style={{border: "solid #c1c1c1 2px"}}>
        <div className="absolute" style={{ right: "0px", top: "0px" }} >
          <i 
            className="fa fa-lg fa-times p2 mr2" 
            onClick={() => toggleNewForm()}
          />
        </div>
        <p className="mb0">Title</p>
        <input
          type="text"
          style={{ width: "100%" }}
          name="name"
          onChange={this.handleInputChange}
        />
        <p className="mb0">Url</p>
        <input
          type="text"
          style={{ width: "100%" }}
          name="url"
          onChange={this.handleInputChange}
        />
        <button
          className="button button-black button-small w100"
          onClick={this.handleSave}
        >
          Save
        </button>
      </div>
    )
  }
}
