import React, { Component } from "react";

export default class UserIncomeReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      buckets: props.report.buckets || [],
    };
  }

  render() {
    const { buckets } = this.state;

    return (
      <div>
        <table className="styled-table">
          <thead>
            <tr>
              <th>Brand</th>
              <th>Commission</th>
            </tr>
          </thead>
          <tbody>
            {
              buckets.map((brand) =>
                <tr>
                  <td>{brand.fields.merchantName}</td>
                  <td>${brand.sumValue}</td>
                </tr>
              )
            }
          </tbody>
        </table>
        {buckets.length == 0 && (
          <div className="flex">
            <div className="w100 flex-column bg-white p4 my2 rounded-large">
              <p className="bold center-align">You have no sales yet</p>
              <p className="center-align">
                Income from monetized products will be dispalyed here
              </p>
            </div>
          </div>
        )}
      </div>
    );
  }
}
