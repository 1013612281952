import React, { Component } from "react";
import Chart from 'react-apexcharts'

export default class Analytics extends Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [
        {
          data: props.series.analytics
        }
      ]
    };
  }


  async series() {
    const analytics = this.props.series.analytics;
    const series = [];

    await analytics.map((report) => {
      series.push({ x: report.count, y: report.date });
    });

    this.setState({ series: series });
  }

  render() {
    const analytics = this.props.series.analytics;

    const options = {
      chart: {
        type: "bar"
      },
      title: {
        text: "Last 28 days",
        align: 'left',
      },
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            position: 'top'
          }
        },
        dataLabels: {
          enabled: true,
          offsetX: 30
        },
      },
      stroke: {
        width: 1,
        colors: ["#fff"]
      },
      xaxis: {
        type: 'category',
        title: {
          text: "Clicks",
          offsetX: 0,
          offsetY: 0,
        }
      },
      legend: {
        position: "right",
        verticalAlign: "top",
        containerMargin: {
          left: 35,
          right: 60
        }
      }
    }

    return (
      <div>
        <Chart options={options} series={this.state.series} type="bar" width="100%" height="500%" />
      </div>
    );
  }
}
