import React from "react";
import { PropTypes } from "prop-types";
import InfiniteScroll from "react-infinite-scroller";
import { ClipLoader } from "react-spinners";
import TagCard from "../taggable_products/TagCard";
import ProductService from "../services/ProductService";
var mobile = require("is-mobile");

export default class List extends React.Component {
  static propTypes = {
    products: PropTypes.array,
    authToken: PropTypes.string,
  };

  componentDidMount() {
    this.productService = new ProductService();
  }

  render() {
    const {
      products,
      isLoading,
      hasMore,
      loadMore,
      media,
      authToken,
      loadProducts,
      selectProduct,
      removeMediaProduct,
    } = this.props;

    const hasProducts = products.length >= 1;
    const isMobile = mobile();

    const cols = isMobile ? [[], []] : [[], [], []];
    const indexCount = isMobile ? 2 : 3;
    const cssColumns = isMobile ? "c1in2" : "c1in3";

    products.map((media, index) => {
      cols[index % indexCount].push(media);
    });

    return (
      <div>
        {hasProducts && (
          <InfiniteScroll
            pageStart={0}
            isLoading={isLoading}
            loadMore={loadMore}
            hasMore={hasMore && !isLoading}
            className="flex flex-wrap justify-between cell-list masonry px1"
            useWindow
          >
            {cols.map((col, index) => (
              <div className={`${cssColumns} flex flex-column`} key={index}>
                {col.map((product) => (
                  <TagCard
                    authToken={authToken}
                    media={media}
                    product={product}
                    loadProducts={loadProducts}
                    selectProduct={selectProduct}
                    removeMediaProduct={removeMediaProduct}
                  />
                ))}
              </div>
            ))}
          </InfiniteScroll>
        )}
        {isLoading && (
          <div className="flex justify-center loader">
            <ClipLoader color={"#000000"} loading={true} />
          </div>
        )}
        {!hasProducts && !isLoading && (
          <div>
            <p className="flex justify-center bold">No products</p>
            <p className="flex justify-center px2">
              Create a product then tag them on your Instagram posts.
            </p>
          </div>
        )}
      </div>
    );
  }
}
