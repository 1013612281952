import React, { Component } from "react";
import { PropTypes } from "prop-types";
import copy from "copy-to-clipboard";
import ClickEventService from "../services/ClickEventService";

export default class Card extends Component {
  static propTypes = {
    product: PropTypes.object,
  };

  componentWillMount() {
    this.state = {
      showMenu: false,
      showRedirectMessage: false,
      openPageInSeconds: 3,
    };

    this.clickEventService = new ClickEventService();
  }

  toggleMenu() {
    const { showMenu } = this.state;

    this.setState({ showMenu: !showMenu });
  }

  editUrl(product) {
    if (location.hostname == "localhost") {
      window.location = `http://localhost:3000/${product.user.slug}/products/${product.uuid}/edit`;
    } else {
      window.location = `https://www.shopmyfeed.io/${product.user.slug}/products/${product.uuid}/edit`;
    }
  }

  anlyticsUrl(product) {
    if (location.hostname == "localhost") {
      window.location = `http://localhost:3000/analytics?product_uuid=${product.uuid}`;
    } else {
      window.location = `https://www.shopmyfeed.io/analytics?product_uuid=${product.uuid}`;
    }
  }

  createClickEvent() {
    const { product } = this.props;
    const params = {
      product_uuid: product.uuid,
    };

    this.clickEventService.create(params);
  }

  redirectToBrandsProductPage(product_url) {
    const { product } = this.props;

    this.createClickEvent();

    if (product.promo_code != null) {
      copy(product.promo_code);

      this.setState({ showRedirectMessage: true });

      setTimeout(() => {
        this.setState({ openPageInSeconds: 2 });
      }, 1000);

      setTimeout(() => {
        this.setState({ openPageInSeconds: 1 });
      }, 2000);

      setTimeout(() => {
        this.setState(
          { showRedirectMessage: false, openPageInSeconds: 0 },
          (window.location = product_url)
        );
      }, 3000);
    } else {
      window.location = product_url;
    }
  }

  render() {
    const { product, deleteTag, index, styles } = this.props;
    const { showMenu, showRedirectMessage, openPageInSeconds } = this.state;

    const backgroundColor = styles == undefined ? "white" : styles.button_css.backgroundColor
    const buttonTextColour = styles == undefined ? "black" : styles.button_css.color
    const border = styles == undefined ? "1px solid #cccccc" : styles.button_css.border

    return (
      <div className="px1" key={product.uuid} id={`product-${product.uuid}`}>
        { showRedirectMessage && (
          <div
            className="fixed-position w100 z5 bg-black p2 large-shadow"
            style={{ top: "0px", left: "0px" }}
          >
            <i className="relative fa fa-4x pb2 white fa-copy flex justify-center" />
            <h1 className="text-white center-align bold">Hang Tight</h1>
            <p className="text-white center-align">{`Promo code ${product.promo_code} copied to clipboard`}</p>
            <p className="text-white center-align">{`Redirecting in ${openPageInSeconds} seconds`}</p>
          </div>
        )}

        <div className="card pointer flex flex-column relative mb2 w100 test-card" style={{ backgroundColor: backgroundColor, border: border }}>
          { deleteTag && (
            <div className="absolute pointer r0" id="toggle-product-menu">
              <div
                className="relative z2 pr1 pt1"
                onClick={() => this.toggleMenu()}
              >
                <div
                  className="bg-white"
                  style={{
                    width: "24px",
                    height: "24px",
                    borderRadius: "12px",
                  }}
                >
                  <i
                    className="fa fa-ellipsis-v text-grey-xdark pointer pl2"
                    style={{ paddingTop: "4px", paddingLeft: "10px" }}
                  />
                </div>
                {showMenu && (
                  <div
                    className="absolute bg-white rounded p2 mt1"
                    style={{ right: "4px" }}
                  >
                    <p onClick={() => this.editUrl(product)}>Edit</p>
                    <p onClick={() => this.anlyticsUrl(product)}>Analytics</p>
                    <p onClick={() => deleteTag(product)} id="delete">
                      Delete
                    </p>
                  </div>
                )}
              </div>
            </div>
          )}
          <a
            onClick={() =>
              this.redirectToBrandsProductPage(product.redirect_url)
            }
            target="_blank"
          >
            <img className="rounded-top w100" src={product.medium_image_url} />
            <div className="px2 pb2">
              <div className="font-smaller bold mt1 uppercase" style={{ color: buttonTextColour }}>
                {index == undefined
                  ? product.title
                  : `${index}) ${product.title}`}
              </div>
              <div className="font-small mt1" style={{ color: buttonTextColour }}>
                {product.price} {product.currency_code}
              </div>
              {product.promo_code && (
                <div className="flex font-smaller mt1" style={{ color: buttonTextColour }}>
                  <div className="bg-brand-tan px2 border-grey rounded">
                    {`Promo: ${product.promo_code}`}
                  </div>
                </div>
              )}
            </div>
          </a>
        </div>
      </div>
    );
  }
}
