import React, { Component } from "react";
import copy from 'copy-to-clipboard';
import store from "../store";

export default class ProfileHeader extends Component {
  constructor(props) {
    super(props);

    store.subscribe(() =>
      this.setState({ styles: store.getState().styles })
    )

    this.state = {
      styles: store.getState().styles,
      isCopied: false
    };
  }

  underline(url) {
    const { styles } = this.state;
    const underline = {
      color: styles.links_text_colour,
      borderBottom: `1px solid ${styles.links_text_colour}`
    }

    if (window.location.pathname == url) {
      return underline
    } else {
      return { color: styles.links_text_colour }
    }
  }

  copyProfileUrl() {
    const { user } = this.props;
    const url = `https://shopmyfeed.io/${user.slug}`

    this.setState({ isCopied: true })
    copy(url)

    setTimeout(() => {
      this.setState({ isCopied: false });
    }, 2000);
  }

  render() {
    const { user } = this.props;
    const { styles } = this.state;
    const missingHeaderUrl = styles.header_url == undefined

    return (
      <div className="pb3">
        <div className="container-no-padding max-w800px relative" >
          {
            !missingHeaderUrl &&
            <div>
              <div className="container-no-padding">
                <div className="absolute w100 max-w800px" >
                  <div className="absolute r0 z2 w100" >
                    <img src={styles.header_url} className="z1 profile-header-image" />
                  </div>
                </div>
              </div>
              <div className="relative h250px" >
                <div className="absolute bottom-0 w100">
                  <div className="flex justify-center" >
                    <img src={styles.avatar_url} className="z2 header-avatar" />
                  </div>
                  <div className="font-large extra-bold font-brand center-align bold pb1" style={{ color: styles.header_text_colour }} >
                    {user.username}
                  </div>
                </div>
              </div>
            </div>
          }

          {
            missingHeaderUrl && <div className="relative pt3" >
              <div className="flex justify-center" >
                <img src={styles.avatar_url} className="z2 header-avatar" />
              </div>
              <div className="font-large extra-bold font-brand center-align bold pb1" style={{ color: styles.header_text_colour }} >
                @{user.username}
              </div>
            </div>
          }

          <div className="flex justify-center pl1">
            <a href={`/${user.slug}`}>
              <span className="black bold" style={this.underline(`/${user.slug}`)}>Links</span>
            </a>
            <a href={`/${user.slug}/images`} className="pl3" >
              <span className="black bold" style={this.underline(`/${user.slug}/images`)}>Shop Feed</span>
            </a>
            <a href={`/${user.slug}/products`} className="pl3">
              <span className="black bold" style={this.underline(`/${user.slug}/products`)}>Products</span>
            </a>
          </div>
        </div>
      </div>
    );
  }
}