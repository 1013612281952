import React, { Component } from "react";
import ProfileSetting from "../services/ProfileSetting";
import { ClipLoader } from "react-spinners";
import store from "../store";
import { bindActionCreators } from "redux";

export default class ProfileImages extends Component {
  constructor() {
    super()

    this.state = {
      styles: store.getState().styles,
      loading: false
    }

    store.subscribe(() =>
      this.setState({styles: store.getState().styles })
    )

    this.profileSettingService = new ProfileSetting();
  }

  handleImageChange = async (target, event) => {
    const { styles } = this.state;
    const { authToken } = this.props;
    const base64Image = await this.convertToBase64(event.target.files[0]);
    
    const key = target;
    const params = {};
    params[key] = base64Image

    if (key == "background_base_64") {
      params["bg_gradient"] = {}
      params["bg_colour"] = ""
    }

    this.setState({ loading: true })
    await this.profileSettingService.update(authToken, { profile_setting: params, uuid: styles.uuid});
    location.reload();
  };
  
  async convertToBase64(file) {
    return new Promise(resolve => {
      let baseURL = "";
      let reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  }
  
  removeHeaderImage = async (type) => {
    const { styles } = this.state;
    const { authToken } = this.props;
    
    this.setState({ loading: true })
    const params = {}
    params[type] = null
    await this.profileSettingService.update(authToken, { profile_setting: params, uuid: styles.uuid});
    location.reload()
  }
  
  render() {
    const  { loading, styles } = this.state;

    const missingHeaderUrl = styles.header_url == undefined
    const missingBackgroundUrl = styles.background_url == undefined 

    return (
      <div className="container-no-padding max-w800px bg-white rounded p3 rounded font-small">
        <p className="bold">Avatar</p>
        <input
          style={{ width: "100%" }}
          name="avatar_image"
          type="file"
          onChange={(e) => this.handleImageChange("avatar_base_64", e)}
        />

        <img className="w100px py2" src={styles.avatar_url} />

        <p className="bold mb0">Header</p>
        <p>Best size 800px x 150px</p>
        <input
          style={{ width: "100%" }}
          name="header_image"
          type="file"
          onChange={(e) => this.handleImageChange("header_base_64",e)}
        />

        {
          !missingHeaderUrl && 
          <div className="relative flex" style={{ display: "inline-block"}}>
            <i
              className="absolute right-0 fa fa-lg fa-times text-red pointer p2 bg-white border-full border-black"
              style={{ borderRadius: "100px", right: "-17px", top: "-4px" }}
              onClick={() => this.removeHeaderImage("header_image")}
            />
            <img className="w100px py2" src={styles.header_url} />
          </div>
        }

        <p className="bold">Background Image</p>
        <input
          style={{ width: "100%" }}
          name="background_image"
          type="file"
          onChange={(e) => this.handleImageChange("background_base_64",e)}
        />

        {
          !missingBackgroundUrl && 
          <div className="relative flex" style={{ display: "inline-block"}}>
            <i
              className="absolute right-0 fa fa-lg fa-times text-red pointer p2 bg-white border-full border-black"
              style={{ borderRadius: "100px", right: "-17px", top: "-4px" }}
              onClick={() => this.removeHeaderImage("background_image")}
            />
            <img className="w100px py2" src={styles.background_url} />
          </div>
        }

        {
          loading && <div className="flex justify-center">
            <div className="sweet-loading">
              <ClipLoader color={"#000000"} loading={true} />
            </div>
          </div>
        }
      </div>
    );
  }
}