import React from 'react';
import PropTypes from 'prop-types';
import ProductService from "../services/ProductService";
import List from "./List";

export default class UserProducts extends React.Component {
  static propTypes = {
    authToken: PropTypes.string
  };

  constructor(props) {
    super(props);

    this.state = {
      products: [],
      page: 1,
      hasMore: false,
      isLoading: true
    };

    this.productService = new ProductService();
    this.loadProducts();
  }

  loadProducts = async () => {
    const { authToken, profileUser } = this.props;
    const { page, products } = this.state;

    const response = await this.productService.index(
      page,
      authToken,
      profileUser
    );
    const list = response.data.length === 0 ? [] : response.data;

    this.setState({
      products: products.concat(list),
      hasMore: response.has_more,
      isLoading: false
    });
  };

  loadMore = () => {
    const { products, hasMore } = this.state;

    if ((products.length > 0) & hasMore) {
      this.setState(
        {
          page: this.state.page + 1,
          isLoading: true
        },
        this.loadProducts
      );
    }
  };

  render() {
    const { products, isLoading, hasMore } = this.state;
    const { authToken, deleteTag, styles } = this.props;

    return (
      <div>
        <List
          products={products}
          authToken={authToken}
          isLoading={isLoading}
          hasMore={hasMore}
          loadMore={this.loadMore}
          deleteTag={deleteTag}
          styles={styles}
        />
      </div>
    );
  }
}
