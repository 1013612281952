import React, { Component } from "react";
import LinksService from "../services/LinksService"
import ClickEventService from "../services/ClickEventService";
import New from "./New";
import Link from "./Link";
import { ClipLoader } from "react-spinners";
import ProfileHeader from "../users/ProfileHeader";
import store from "../store";

export default class LinkList extends React.Component {
  constructor(props) {
    super(props);

    this.state =  {
      links: [],
      loading: true,
      showNewForm: false,
      styles: store.getState().styles
    }

    store.subscribe(() =>
      this.setState({ styles: store.getState().styles })
    )

    this.linksService = new LinksService()
    this.clickEventService = new ClickEventService();
    this.loadLinks()
  }

  async loadLinks() {
    const { user } = this.props

    const res = await this.linksService.index(user.uuid)

    this.setState({links: res, loading: false})
  }

  toggleNewForm = () => {
    const { showNewForm }  = this.state;

    this.setState({ showNewForm: showNewForm == false ? true : false })
  }

  addLinkToList = (link) => {
    const links = this.state.links

    this.setState({ links: links.concat(link)})
  }

  updateLinkList = (removeLink) => {
    const { links } = this.state;

    const updatedLink = links.filter(l => l.uuid != removeLink.uuid)
    this.setState({ links: updatedLink })
  }

  render() {
    const { links, showNewForm, loading, styles } = this.state;
    const { authToken, user } = this.props;

    return (
      <div className="container-no-padding max-w800px relative px2">
        {
          loading && <div className="flex justify-center pb2">
            <div className="bg-white p1" style={{ borderRadius: "40px", display: "inherit" }}>
              <ClipLoader color={"#000000"} loading={loading}  />
            </div>
          </div>
        }

        {
          !loading  && links.map(link =>
            <Link link={link} authToken={authToken} styles={styles} updateLinkList={this.updateLinkList} />
          )
        }

        <div className="flex justify-center pb3" >
          { styles.instagram_url &&
            <a href={`${styles.instagram_url}`}>
              <i className="fab fa-2x fa-instagram px1" style={{ color: styles.social_colour }}/>
            </a>
          }

          { styles.facebook_url &&
            <a href={`${styles.facebook_url}`}>
              <i className="fab fa-2x fa-facebook px1" style={{ color: styles.social_colour }}/>
            </a>
          }

          { styles.youtube_url &&
            <a href={`${styles.youtube_url}`}>
              <i className="fab fa-2x fa-youtube px1" style={{ color: styles.social_colour }}/>
            </a>
          }

          { styles.twitter_url &&
            <a href={`${styles.twitter_url}`}>
              <i className="fab fa-2x fa-twitter px1" style={{ color: styles.social_colour }}/>
            </a>
          }

          { styles.twitch_url &&
            <a href={`${styles.twitch_url}`}>
              <i className="fab fa-2x fa-twitch px1" style={{ color: styles.social_colour }}/>
            </a>
          }

          { styles.tiktok_url &&
            <a href={`${styles.tiktok_url}`}>
              <i className="fab fa-2x fa-tiktok px1" style={{ color: styles.social_colour }}/>
            </a>
          }

          { styles.linkedin_url &&
            <a href={`${styles.linkedin_url}`}>
              <i className="fab fa-2x fa-linkedin-in px1" style={{ color: styles.social_colour }}/>
            </a>
          }
        </div>

        {
          showNewForm && <New authToken={authToken} toggleNewForm={this.toggleNewForm} addLinkToList={this.addLinkToList}/>
        }          

        {
          authToken && <div className="flex justify-center mt2">
            <div className="flex items-center hover bold bg-white p1 mb3" style={{borderRadius: "50px" }} onClick={this.toggleNewForm} >
              <i 
                className="fa fa-lg fa-plus p2 mr2 border-full border-black" 
                style={{borderRadius: "30px", paddingTop: "11px", backgroundColor: "white", color: "black" }}
              />
              Add Link
            </div>
          </div>
        }
      </div>
    )
  }
}