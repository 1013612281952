import React, { Component } from "react";
import { SketchPicker } from "react-color";
import Modal from "react-modal";

export default class ColorPicker extends Component {
  constructor(props) {
    super(props)

    this.state = {
      color: props.color
    }
  }

  colorChange(target, hexColor) {
    const { color, handleChange } = this.props;
    
    this.setState({ color: hexColor })
    handleChange(target, hexColor)
  }

  render() {
    const { isOpen, close, target } = this.props;
    const { color } = this.state;

    return (
      <div>
        <Modal
          isOpen={isOpen}
          className="bg-white p2 center max-w250px mt2"
          overlayClassName="colour-picker-overlay"
        >
          <div className="flex justify-end">
            <i
              className="fa fa-lg fa-times text-grey-xdark pointer pr1 mb2"
              onClick={() => close()}
            />
          </div>
          <div className="flex justify-center">
            <SketchPicker color={color} onChange={(event) => this.colorChange(target, event.hex)} />
          </div>
        </Modal>
      </div>
    );
  }
}
