import React from "react";
import { PropTypes } from "prop-types";
import MeidaProductService from "../services/MediaProductService";
import List from "../taggable_products/List";
import InstagraProductService from "../services/InstagramPostProductsService";

export default class TaggableProducts extends React.Component {
  static propTypes = {
    authToken: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      products: [],
      page: 1,
      hasMore: false,
      isLoading: true,
      product_uuid: null,
      top: 0,
      left: 0,
    };

    this.meidaProductService = new MeidaProductService();
    this.instagramPostProductService = new InstagraProductService();
    this.loadProducts();
  }

  loadProducts = async (restart) => {
    const { media, user_media_uuid } = this.props;
    const { page, products } = this.state;

    const response = await this.meidaProductService.index(
      page,
      media.public_id,
      true,
      user_media_uuid
    );

    const list = response.data.length === 0 ? [] : response.data;

    this.setState({
      products: products.concat(list),
      hasMore: response.has_more,
      isLoading: false,
    });
  };

  loadMore = () => {
    const { products, hasMore } = this.state;

    if ((products.length > 0) & hasMore) {
      this.setState(
        {
          page: this.state.page + 1,
          isLoading: true,
        },
        this.loadProducts
      );
    }
  };

  async tagProduct(top, left) {
    this.setState({ isLoading: true });
    const { media, authToken, addMediaProduct, resetModel } = this.props;

    const { product_uuid } = this.state;

    const params = {
      instagram_post_product: {
        product_uuid: product_uuid,
        media_id: media.media_id,
        short_code: media.public_id,
        top: top,
        left: left,
      },
    };

    const response = await this.instagramPostProductService.create(
      params,
      authToken
    );

    const taggedProduct = response.product;
    taggedProduct.instagram_post_product_uuid = response.uuid;
    taggedProduct.top = top;
    taggedProduct.left = left;
    addMediaProduct(taggedProduct);
    this.setState({ isLoading: false });
    resetModel();
  }

  selectProduct = (product) => {
    const { setTitle } = this.props;

    this.setState({ product_uuid: product.uuid });
    setTitle("Click on product location in image");
  };

  _onMouseMove(e) {
    const myImg = document.querySelector("img.max-w100");
    const top = (e.nativeEvent.offsetY / myImg.clientHeight) * 100;
    const left = (e.nativeEvent.offsetX / myImg.clientWidth) * 100;

    this.setState({
      top,
      left,
    });
  }

  setProductLocation() {
    const { top, left } = this.state;
    this.tagProduct(top, left);
  }

  render() {
    const { products, isLoading, hasMore, product_uuid } = this.state;
    const { media, authToken, removeMediaProduct } = this.props;
    const productSelected = product_uuid != null;

    return (
      <div>
        {productSelected && (
          <div className="flex justify-center bg-grey-xxlight px2">
            <div className="max-w600px">
              <img
                onMouseMove={this._onMouseMove.bind(this)}
                onClick={this.setProductLocation.bind(this)}
                src={media.img_path}
                className="max-w100"
              />
            </div>
          </div>
        )}
        {!productSelected && (
          <List
            products={products}
            media={media}
            authToken={authToken}
            isLoading={isLoading}
            hasMore={hasMore}
            loadMore={this.loadMore}
            loadProducts={this.loadProducts}
            selectProduct={this.selectProduct}
            removeMediaProduct={removeMediaProduct}
          />
        )}
      </div>
    );
  }
}
