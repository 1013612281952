import React from 'react';
import PropTypes from 'prop-types';

export default class ImageChanger extends React.Component {
  static propTypes = {
    imageUrls: PropTypes.array,
  };

  constructor(props) {
    super();

    this.state = {
      image1: props.imageUrls[0],
      image2: props.imageUrls[1],
      image3: props.imageUrls[2],
      displayed_image_url: props.imageUrls[0],
    };

    this.setImage();
  }

  setImage() {
    setTimeout(() => {
      this.setState(
        { displayed_image_url: this.displayImage() },
        this.setImage()
      );
    }, 2000);
  }

  displayImage() {
    const { displayed_image_url } = this.state;

    if (displayed_image_url == this.props.imageUrls[0]) {
      return this.props.imageUrls[1];
    } else if (displayed_image_url == this.props.imageUrls[1]) {
      return this.props.imageUrls[2];
    } else {
      return this.props.imageUrls[0];
    }
  }

  render() {
    const { displayed_image_url } = this.state;

    return (
      <div>
        <img className="w100" src={displayed_image_url} />
      </div>
    );
  }
}
