import React, { Component } from "react";
import ProfileColours from "./ProfileColours";
import ProfileImages from "./ProfileImages";
import ProfileSocials from "./ProfileSocials";
import ProfileButtons from "./ProfileButtons";
import store from "../store";
import produce from "immer"

export default class ProfileSettings extends Component {
  constructor() {
    super()

    this.state = {
      showColours: true,
      showImages: false,
      showSettings: false,
      showSocials: false,
      showButtons: false
    }
  }

  showTab(tab) {
    this.setState({
      showColours: false,
      showImages: false,
      showSettings: false,
      showSocials: false,
      showButtons: false
    })

    this.setState({[tab]: true})
  }

  undertlineTab(liveTab) {
    const { styles } = this.props;

    if(this.state[liveTab]) {
      return { borderBottom: `1px solid black` }
    } 
  }

  updateReduxState = (target, value) => {
    const styles = store.getState().styles
    
    styles[target] = value

    const nextState = produce(styles, draftState => {
      draftState[target] = value
    })

    store.dispatch({ type: 'counter/incremented', payload: nextState })
  }


  render() {
    const { user, authToken, close } = this.props;
    const { showColours, showImages, showSocials, showButtons } = this.state;
    const styles = store.getState().styles

    return (
      <div className="bg-grey-xxlight">
        <div className="container-no-padding max-w800px">
          <div>
            <div className="flex justify-between">
              <div className="flex items-center font-small">
                <div className="px2">
                  <div className="py2" onClick={() => this.showTab("showColours")} style={this.undertlineTab("showColours")}>
                    Colours
                  </div>
                </div>
                <div className="px2" onClick={() => this.showTab("showButtons")} style={this.undertlineTab("showButtons")}>
                  <div className="py2">
                    Links
                  </div>
                </div>
                <div className="px2" onClick={() => this.showTab("showImages")} style={this.undertlineTab("showImages")}>
                  <div className="py2">
                    Images
                  </div>
                </div>
                <div className="px2" onClick={() => this.showTab("showSocials")} style={this.undertlineTab("showSocials")}>
                  <div className="py2">
                    Socials
                  </div>
                </div>
              </div>
              <div className="flex items-center">
                <i className="fa fa-lg fa-times pr2 pointer" onClick={() => close()} />
              </div>
            </div>

          </div>

          <div className="p2">
            {
              showColours &&
              < ProfileColours
                user={user}
                authToken={authToken}
                updateReduxState={this.updateReduxState}
              />
            }

            {
              showImages &&
              <ProfileImages 
                user={user}
                authToken={authToken}
                updateStyles={this.updateStyles}
              />
            }

            {
              showSocials &&
              <ProfileSocials
                user={user}
                authToken={authToken}
                updateReduxState={this.updateReduxState}
              />
            }

            {
              showButtons &&
              <ProfileButtons
                user={user}
                authToken={authToken}
                updateReduxState={this.updateReduxState}
              />
            }
          </div>
        </div>
      </div>
    );
  }
}